export type Languages = 'it' | 'en'

export const translateError = (key: string) => {
  if (!errors[key]) key = 'server_error'
  let lang: Languages = 'en'
  if (navigator.language === 'it' || navigator.language === 'it-IT') lang = 'it'
  return errors[key][lang] || key
}

export const errors: { [key: string]: { [key in Languages]: string } } = {
  server_error: {
    it: 'Errore server. Riprovare più tardi.',
    en: 'Server error. Try again later.',
  },
  invalid_server_response: {
    it: 'Risposta server non valida. Riprovare più tardi.',
    en: 'Invalid server response. Try again later.',
  },
  login_wrong_credentials: {
    it: 'Credenziali di accesso errate.',
    en: 'Wrong login credentials.',
  },
  forbidden: {
    it: "Non si hanno i permessi per effettuare l'operazione.",
    en: "You don't have permission to execute this operation.",
  },
  insufficient_role: {
    it: 'Non hai un ruolo sufficientemente elevato.',
    en: `Your role doesn't allow this action.`,
  },
  login_wrong_otp_credentials: {
    it: 'Il codice è errato.',
    en: 'Wrong code.',
  },
}
