import { User } from './models'
import { deleteToken as deleteFirebaseToken } from '../libs/firebase'
import moment from 'moment'

class Storage {
  getToken = () => localStorage.getItem('jwt')
  saveToken = (token: string) => localStorage.setItem('jwt', token)
  deleteToken = () => localStorage.removeItem('jwt')

  getUser = () => JSON.parse(localStorage.getItem('user') || 'null') as User | null
  saveUser = (user: User) => localStorage.setItem('user', JSON.stringify(user))
  deleteUser = () => localStorage.removeItem('user')

  deleteFirebaseDB = () => {
    window.indexedDB.deleteDatabase('firebase-messaging-database')
    window.indexedDB.deleteDatabase('firebase-installations-store')
  }

  cleanAll = async () => {
    storage.deleteToken()
    storage.deleteUser()
    deleteFirebaseToken()
    // storage.deleteFirebaseDB()
  }

  getLastUpdated = () => moment(localStorage.getItem('lastUpdated') || '1970-01-01').toDate()
  saveLastUpdated = () => localStorage.setItem('lastUpdated', new Date().toISOString())
}
export const storage = new Storage()
