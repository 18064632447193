import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Radios } from '@duckma/react-ds'
import styled from 'styled-components'
import _ from 'lodash'

import { ControlledField, ControlledForm, LocalizedInput } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, UpdateUser } from '../../data/api'
import { storage } from '../../data/storage'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { PasswordField } from '../../components/PasswordField'
import { LocalizedButton } from '../../components/Button'
import { useSession } from '../../contexts/SessionContext'
import { translate } from '../../i18n/dashboard_labels'
import { LocalizedSectionTitle } from '../../components/Typography'

export const ProfilePage = () => {
  const { mustReload } = useSession()
  const [request, { status, data }] = useRequest(api.getMe)
  const [updateProfile, { status: updateStatus, data: updateData }] = useRequest(api.updateMe)
  const [updatePassword, { status: updatePasswordStatus }] = useRequest(api.updatePassword)

  const [values, setValues] = useState<Partial<UpdateUser>>({})
  const [valid, setValid] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [validPassword, setValidPassword] = useState(true)

  const typeChoices = [
    { key: 'private', label: translate('private') },
    { key: 'company', label: translate('company') },
  ]

  useEffect(() => {
    if (status === 'idle') {
      request({})
    }
  }, [request, status])

  useEffect(() => {
    if (updateStatus === 'success' && updateData) {
      // Update session user
      storage.saveUser(updateData)
      mustReload()
      request({})
    }
  }, [updateStatus, updateData, mustReload, request])

  useSuccessToast(updateStatus, 'profile_data_modified', '/me')
  useSuccessToast(updatePasswordStatus, 'password_modified')

  useEffect(() => {
    if (updatePasswordStatus === 'success') {
      setNewPassword('')
    }
  }, [updatePasswordStatus])

  useEffect(() => {
    if (data) {
      setValues((vals) => ({
        ...vals,
        type: data.type,
        tax_code_or_vat_code: data.tax_code_or_vat_code,
      }))
    }
  }, [data])

  const dataObject = useMemo(
    () =>
      _.pick(data, ['first_name', 'last_name', 'email', 'business_name', 'phone_number']) as Record<
        string,
        string
      >,
    [data]
  )
  const profileOnChange = useCallback((v, valid) => {
    setValues((values) => ({ ...values, ...v }))
    setValid(valid)
  }, [])

  return (
    <Container>
      <FormContainer>
        <ControlledForm initialValues={data ? dataObject : null} onChange={profileOnChange}>
          <ControlledField
            name="email"
            type="email"
            label=""
            size="large"
            fieldName="email"
            disabled
            required
          />
          <ControlledField
            name="first_name"
            label=""
            placeholder="first_name"
            size="large"
            fieldName="first_name"
            required
          />
          <ControlledField
            name="last_name"
            label=""
            placeholder="last_name"
            fieldName="last_name"
            size="large"
            required
          />
          <ControlledField
            name="phone_number"
            label=""
            placeholder="phone_number"
            size="large"
            fieldName="phone_number"
          />
          <ControlledField
            name="business_name"
            label=""
            placeholder="business_name"
            size="large"
            fieldName="business_name"
          />
          <form autoComplete="off">
            <PasswordField
              value={newPassword}
              placeholder="insert_password_placeholder"
              onChange={(v, valid) => {
                setNewPassword(v)
                setValidPassword(valid)
              }}
              hideLabel
              size="large"
            />
          </form>
          <div>
            <LocalizedSectionTitle text="complete_profile" style={{ marginBottom: '30px' }} />
            <Radios
              value={(values.type as string) ?? '-'}
              choices={typeChoices}
              onChange={(type) => {
                setValues((v) => ({
                  ...v,
                  type: type as 'private' | 'company',
                  tax_code_or_vat_code: '',
                }))
              }}
            />
            {['private', 'company'].includes(values.type as string) && (
              <LocalizedInput
                style={{ marginTop: '10px' }}
                name="tax_code_or_vat_code"
                label={values.type === 'private' ? 'tax_code' : 'vat_code'}
                placeholder={values.type === 'private' ? 'tax_code' : 'vat_code'}
                size="large"
                required
                value={values.tax_code_or_vat_code as string | undefined}
                onChange={(tax_code_or_vat_code) =>
                  setValues((v) => ({ ...v, tax_code_or_vat_code }))
                }
              />
            )}
          </div>
        </ControlledForm>
      </FormContainer>
      <div style={{ flexGrow: 1 }} />
      <FooterContainer>
        <LocalizedButton
          text="save_changes"
          radius={50}
          outline
          disabled={
            status !== 'success' ||
            !valid ||
            (newPassword !== '' && !validPassword) ||
            !['private', 'company'].includes(values.type as string) ||
            (values.tax_code_or_vat_code?.length ?? 0) <= 0
          }
          loading={updateStatus === 'loading' || updatePasswordStatus === 'loading'}
          onClick={() => {
            updateProfile(values)
            if (newPassword !== '') {
              updatePassword({ password: newPassword })
            }
          }}
          style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
        />
      </FooterContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    @media screen and (max-width: 599px) {
      flex-basis: 100%;
    }
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  display: flex;
  margin-bottom: 66px;
`
