import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Icon, useTheme } from '@duckma/react-ds'

import { passwordValidator, PasswordValidatorOutput } from '../utils/passwordValidator'
import { translate } from '../i18n/dashboard_labels'
import { LocalizedInput } from './ControlledForm'

type FieldProps = {
  value: string
  onChange: (password: string, valid: boolean) => void
  placeholder?: string
  label?: string
  size?: 'large' | 'small'
  hideLabel?: boolean
  className?: string
}
export const PasswordField = ({
  value,
  onChange,
  placeholder,
  label,
  size,
  className,
  hideLabel = false,
}: FieldProps) => {
  const [validation, setValidation] = useState<PasswordValidatorOutput>({
    score: 0,
    level: 'none',
    checks: { length: false, uppercaseAndLowercase: false, containsNumbers: false },
  })
  const theme = useTheme()

  const internalOnChange = useCallback(
    (pwd) => {
      const valid = passwordValidator(pwd)
      onChange(
        pwd,
        Object.values(valid.checks).every((v) => v) && ['medium', 'strong'].includes(valid.level)
      )
    },
    [onChange]
  )

  useEffect(() => {
    setValidation(passwordValidator(value))
  }, [value])

  const none = theme['gray50']
  const weak = theme['danger100']
  const medium = theme['warning100']
  const strong = theme['success100']

  let colors
  switch (validation.level) {
    case 'weak':
      colors = [weak, none, none]
      break
    case 'medium':
      colors = [medium, medium, none]
      break
    case 'strong':
      colors = [strong, strong, strong]
      break
    default:
      colors = [none, none, none]
  }

  return (
    <div style={{ width: '100%' }} className={className}>
      <LocalizedInput
        name="password"
        type="password"
        autocomplete="new-password"
        value={value}
        placeholder={placeholder ?? 'password'}
        label={hideLabel ? '' : label ?? 'password'}
        onChange={internalOnChange}
        size={size}
      />
      <IndicatorContainer>
        <Indicator color={colors[0]} />
        <Indicator color={colors[1]} />
        <Indicator color={colors[2]} />
      </IndicatorContainer>
      <ChecksContainer>
        <Check gray={theme['gray100']}>
          <Icon name="ok" color={validation.checks.length ? 'success100' : 'gray100'} size="20px" />
          <span>{translate('at_least_8_characters')}</span>
        </Check>
        <Check gray={theme['gray100']}>
          <Icon
            name="ok"
            color={validation.checks.uppercaseAndLowercase ? 'success100' : 'gray100'}
            size="20px"
          />
          <span>{translate('uppercase_and_lowercase')}</span>
        </Check>
        <Check gray={theme['gray100']}>
          <Icon
            name="ok"
            color={validation.checks.containsNumbers ? 'success100' : 'gray100'}
            size="20px"
          />
          <span>{translate('at_least_a_number')}</span>
        </Check>
      </ChecksContainer>
    </div>
  )
}

const ChecksContainer = styled.div``

const Check = styled.div<{ gray: string }>`
  display: flex;
  align-items: center;
  > :nth-child(2) {
    margin-left: 16px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.gray};
  }
`

const IndicatorContainer = styled.div`
  margin-top: 17px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  width: calc(100% + 1px);
`

const Indicator = styled.div<{ color: string }>`
  flex-basis: 30%;
  height: 7px;
  border-radius: 28px;
  background-color: ${(props) => props.color};
`
