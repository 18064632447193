import React from 'react'
import styled from 'styled-components'
import { Button, useTheme } from '@duckma/react-ds'

import { useLocation, useHistory } from 'react-router-dom'
import { api } from '../data/api'
import { Header } from './Header'
import { version } from '../../package.json'
import { Sidebar } from './Sidebar'
import { translate } from '../i18n/dashboard_labels'
import { useMedia } from 'react-media'
import { useSession } from '../contexts/SessionContext'

const mediaQueries = {
  small: '(max-width: 599px)',
  large: '(min-width: 600px)',
}

export const MainLayout: React.FC<{
  title?: string
  backLink?: { to: string; text: string }
  headerButton?: { text: string; to: string }
  fullScreen?: boolean
}> = ({ children, title, backLink, headerButton, fullScreen = false }) => {
  const theme = useTheme()
  const { sidebarOpen, setSidebarOpen, dirty, updateUser } = useSession()
  const location = useLocation()
  const history = useHistory()
  const matches = useMedia({ queries: mediaQueries })
  // If a form is dirty (with unsaved user work on it) the page sets dirty to true.
  // If the user then tries to go back, a popup is shown

  return fullScreen ? (
    <ContentContainer color={theme['white']} fullScreen>
      {children}
    </ContentContainer>
  ) : (
    <Grid showSidebar={matches.large || sidebarOpen}>
      <SidebarContainer showSidebar={matches.large || sidebarOpen}>
        <Sidebar
          structure={[
            {
              name: 'me',
              label: 'user_data',
              showArrow: false,
            },
            {
              name: 'configurations',
              label: 'configurations',
              showArrow: false,
            },
          ]}
          pinned={[
            {
              name: 'logout',
              label: 'logout',
              showArrow: false,
            },
          ]}
          selected={location.pathname.split('/')[1]}
          color="gray20"
          selectedColor="gray50"
          version={version}
          onSelect={(name) => {
            setSidebarOpen(false)
            switch (name) {
              case 'logout':
                api.clearSession()
                updateUser()
                if (gapi && gapi.auth2) {
                  gapi.auth2
                    .getAuthInstance()
                    .signOut()
                    .then(() => console.log('User signed out.'))
                    .catch((err: any) => console.warn(err))
                }
                history.push('/')
                break
              default:
                if (dirty) {
                  window.location.href = `/${name}`
                } else {
                  history.push(`/${name}`)
                }
                break
            }
          }}
        />
      </SidebarContainer>
      <HeaderContainer color={theme['white']} fullScreen={matches.small && !sidebarOpen}>
        {matches.small && (
          <svg
            width="3em"
            height="3em"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            fill="#828894"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            style={{ cursor: 'pointer', marginLeft: '10vw', marginTop: '5vh' }}
          >
            <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
          </svg>
        )}
        <Header
          title={title || ''}
          backLink={backLink}
          dirty={dirty}
          button={
            headerButton ? (
              <Button
                text={translate(headerButton.text)}
                onClick={() => {
                  if (dirty) {
                    return (window.location.href = headerButton.to)
                  }
                  history.push(headerButton.to)
                }}
                size="large"
                radius={4}
              />
            ) : undefined
          }
        ></Header>
      </HeaderContainer>
      <ContentContainer color={theme['white']} fullScreen={matches.small && !sidebarOpen}>
        {children}
      </ContentContainer>
    </Grid>
  )
}

const Grid = styled.div<{ showSidebar: boolean }>`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  display: grid;
  grid-template-columns: ${(props) => (props.showSidebar ? '260px 1fr' : '1fr')};
  grid-template-rows: 160px 1fr;
`

const SidebarContainer = styled.div<{ showSidebar: boolean }>`
  width: 260px;
  max-height: 100vh;
  height: 100%;
  grid-area: 1 / 1 / 3 / 2;
  visibility: ${(props) => (props.showSidebar ? 'initial' : 'hidden')};
  position: fixed;
  top: 0;
  left: 0;
`

const HeaderContainer = styled.div<{ color: string; fullScreen: boolean }>`
  background-color: ${(props) => props.color};
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-area: ${(props) => (props.fullScreen ? '1 / 1 / 2 / 2' : '1 / 2 / 2 / 3')};
`

const ContentContainer = styled.div<{ color: string; fullScreen: boolean }>`
  background-color: ${(props) => props.color};
  width: 100%;
  grid-area: ${(props) => (props.fullScreen ? '2 / 1 / 3 / 2' : '2 / 2 / 3 / 3')};
`
