import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import LogoImg from '../../images/logo_dark.png'
import { useRequest } from '../../hooks/useRequest'
import { api, Session } from '../../data/api'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { PasswordField } from '../../components/PasswordField'
import { LocalizedSubtitle } from '../../components/Typography'
import { LocalizedButton } from '../../components/Button'

export const UpdatePassword = () => {
  const { state } = useLocation<{ session?: Session }>()
  const history = useHistory()

  const [password, setPassword] = useState('')
  const [valid, setValid] = useState(false)
  const onChange = useCallback((newPassword, isValid) => {
    setPassword(newPassword)
    setValid(isValid)
  }, [])

  const [request, { status, data }] = useRequest(api.updatePassword)

  useEffect(() => {
    if (!state || !state.session) {
      history.push('/me')
    }
  }, [state, history])

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ password, session: state.session! })
      }}
    >
      {data && <Redirect to="/me" />}
      <Logo src={LogoImg} alt="Logo" />
      <LocalizedSubtitle text="insert_new_password_placeholder" style={{ marginBottom: '32px' }} />
      <div style={{ width: '80%' }}>
        <PasswordField
          value={password}
          onChange={onChange}
          placeholder="insert_password"
          size="large"
          hideLabel
        />
      </div>
      <LocalizedButton
        text="continue"
        radius={50}
        disabled={!valid}
        loading={status === 'loading'}
        style={{ width: '50%', marginTop: '50px' }}
      />
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  @media screen and (max-width: 799px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  padding-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Logo = styled.img`
  width: 10vw;
  object-fit: contain;
`
