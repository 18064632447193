/**
 * This file defines various input formatters of general use.
 * Single use functions shouldn't be put here.
 */
import moment from 'moment'

export type Formatter<T> = (value: T) => string

export const dateFormatter: Formatter<string> = (date: string) => moment(date).format('DD/MM/YYYY')

export const priceRounder = (rounded: number) => Math.round(rounded / 5) * 5

export const ellipse = (s: string, n: number = 20) =>
  s.length < n ? s : s.substring(0, n - 3) + '...'
