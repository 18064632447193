import React from 'react'
import styled from 'styled-components'
import { ColorName } from '@duckma/react-ds'
import { theme } from '../styles/theme'

type Props = {
  perc: number
  backgroundColor?: ColorName
  progressColor?: ColorName
  className?: string
}

export const StepsProgress: React.FC<Props> = ({
  perc,
  backgroundColor,
  progressColor,
  className,
}) => {
  return (
    <Background className={className} color={backgroundColor || 'gray20'}>
      <Foreground perc={perc} color={progressColor || 'primary100'} />
    </Background>
  )
}

const Background = styled.div<{ color: ColorName }>`
  width: 100%;
  background-color: ${(props) => theme[props.color]};
`
const Foreground = styled.div<{ perc: number; color: ColorName }>`
  width: ${(props) => props.perc * 100}%;
  height: 2px;
  background-color: ${(props) => theme[props.color]};
`
