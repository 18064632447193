import TagManager from 'react-gtm-module'
import { storage } from './storage'

class Analytics {
  gtmId = process.env.REACT_APP_GTM_ID

  isEnabled() {
    return this.gtmId !== undefined
  }

  initialize() {
    if (this.gtmId) {
      TagManager.initialize({ gtmId: this.gtmId })
    }
  }

  pageChangeEvent(page: string, additionalData?: Record<string, any>) {
    if (!this.isEnabled()) {
      return
    }
    TagManager.dataLayer({
      dataLayer: { event: 'pageChange', page, userId: storage.getUser()?.id, ...additionalData },
    })
  }
}

export const analytics = new Analytics()
analytics.initialize()
