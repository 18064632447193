import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { User } from '../data/models'
import { storage } from '../data/storage'

const SessionContext = createContext<{
  currentUser?: User
  dirty: boolean
  sidebarOpen: boolean
  updateUser: () => void
  mustReload: () => void
  flagDirty: () => void
  resetDirty: () => void
  setSidebarOpen: (open: boolean) => void
}>({
  dirty: false,
  sidebarOpen: false,
  updateUser: () => {},
  mustReload: () => {},
  flagDirty: () => {},
  resetDirty: () => {},
  setSidebarOpen: () => {},
})

export const useSession = () => {
  const context = useContext(SessionContext)

  useEffect(() => {
    context.updateUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return context
}

export const SessionProvider: React.FC = ({ children }) => {
  const [dirty, setDirty] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [user, setUser] = useState(storage.getUser())
  const mustReload = useCallback(() => setUser(storage.getUser()), [])
  const flagDirty = useCallback(() => {
    window.onbeforeunload = () => ''
    setDirty(true)
  }, [])
  const resetDirty = useCallback(() => {
    window.onbeforeunload = () => null
    setDirty(false)
  }, [])
  const updateUser = useCallback(() => {
    setUser(storage.getUser())
  }, [])

  return (
    <SessionContext.Provider
      value={{
        currentUser: user ?? undefined,
        updateUser,
        dirty,
        sidebarOpen,
        mustReload,
        flagDirty,
        resetDirty,
        setSidebarOpen,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
