export type PasswordValidator = (value: string) => number

const validators: PasswordValidator[] = [
  // Positive contributions
  (p) => p.length * 4,
  (p) => (p.length - p.replace(/[^A-Z]/g, '').length) * 2,
  (p) => (p.length - p.replace(/[^a-z]/g, '').length) * 2,
  (p) => p.replace(/[^\d]/g, '').length * 4,
  (p) => p.replace(/[\da-zA-Z]/g, '').length * 6,
  (p) => p.substring(1, p.length - 1).replace(/[a-zA-Z]/g, '').length * 2,

  // Negative contributions
  (p) => (/^[a-zA-Z]+$/.test(p) ? -p.length : 0),
  (p) => (/^\d+$/.test(p) ? -p.length * 16 : 0),
  (p) =>
    Array.from(p)
      .map((char, i) => (i === 0 || !/[A-Z]/g.test(p[i - 1]) ? false : /[A-Z]/g.test(char)))
      .reduce((sum, charValue) => (charValue ? sum - 2 : sum), 0),
  (p) =>
    Array.from(p)
      .map((char, i) => (i === 0 || !/[a-z]/g.test(p[i - 1]) ? false : /[a-z]/g.test(char)))
      .reduce((sum, charValue) => (charValue ? sum - 2 : sum), 0),
  (p) =>
    Array.from(p)
      .map((char, i) => (i === 0 || !/\d/.test(p[i - 1]) ? false : /\d/.test(char)))
      .reduce((sum, charValue) => (charValue ? sum - 2 : sum), 0),
  (p) => -((p.match(/\d\d+/)?.length || 0) * 2),
  (p) => (/^[A-Z][^A-Z]+$/g.test(p) ? -p.length : 0),
  (p) => -((p.match(/\d[^\da-zA-Z]+$/g)?.length || 0) * 2),
  (p) => -((p.match(/^[\da-zA-Z]+[^\da-zA-Z]$/g)?.length || 0) * 2),
  (p) => {
    if (p.length === 0) {
      return 0
    }
    const count = Array.from(p)
      .map((char, i) => i > 0 && char.toLowerCase() === p[i - 1].toLowerCase())
      .reduce((result, eq) => (eq ? result + 1 : result), 0)
    return -((2 ** count * count) / p.length)
  },
]

const countOccurences = (p: string, c: string) =>
  Array.from(p).reduce((count, char) => (char === c ? count + 1 : count), 0)

const entropy = (p: string) =>
  -Array.from(new Set(p))
    .map((char) => countOccurences(p, char))
    .map((count) => count / p.length)
    .reduce((sum, frequency) => sum + frequency * Math.log2(frequency), 0)

export type PasswordValidatorOutput = {
  score: number
  level: 'none' | 'weak' | 'medium' | 'strong'
  checks: {
    length: boolean
    uppercaseAndLowercase: boolean
    containsNumbers: boolean
  }
}
export const passwordValidator: (password: string) => PasswordValidatorOutput = (
  password: string
) => {
  const score = Math.round(
    validators.reduce((sum, v) => sum + v(password || ''), 0) * entropy(password || '')
  )

  const checks = {
    length: password.length >= 8,
    uppercaseAndLowercase: [password.toLowerCase(), password.toUpperCase()].every(
      (p) => p !== password
    ),
    containsNumbers: /\d/.test(password),
  }
  if (score < 1) {
    return { score, level: 'none', checks }
  }
  if (score < 110) {
    return { score, level: 'weak', checks }
  }
  if (score < 220) {
    return { score, level: 'medium', checks }
  }
  return { score, level: 'strong', checks }
}
