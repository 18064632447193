import React, { useState, useCallback, useRef, useEffect } from 'react'
import styled from 'styled-components'

import { ControlledField, ControlledForm } from '../../../../components/ControlledForm'
import { NavigationButton, InfoContainer, StyledTitle } from '../layouts'
import { Description, Button, Label } from '@duckma/react-ds'
import { useNetworkQueue } from '../../../../hooks/useQueue'
import { api } from '../../../../data/api'
import { Prospect, Media } from '../../../../data/models'
import { ellipse } from '../../../../utils/formatters'
import { storage } from '../../../../data/storage'

const defaultInitialValues = { title: '', short_description: '' }

const toBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

type SetupValues = Pick<
  Prospect,
  'title' | 'short_description' | 'customer_name' | 'company_name' | 'attachments'
>
export const InfoPage: React.FC<{
  initialValues?: SetupValues
  onChange: (sv: SetupValues) => void
}> = ({ initialValues = defaultInitialValues, onChange }) => {
  const currentUser = storage.getUser()
  const [values, setValues] = useState<SetupValues>(initialValues)
  const inputRef = useRef<HTMLInputElement>(null)
  const onComplete = useCallback((data: Media) => {
    setValues((v) => ({ ...v, attachments: [...(v.attachments ?? []), data] }))
  }, [])
  const [loadQueue, { enqueue }] = useNetworkQueue(api.createMedia, onComplete)

  const innerOnChange = useCallback((values) => {
    setValues((old) => ({
      ...old,
      title: values.title,
      short_description: (values.short_description ?? '').substring(0, 1999),
      customer_name: values.customer_name,
      company_name: values.company_name,
    }))
  }, [])

  useEffect(() => {
    onChange(values)
  }, [onChange, values])

  return (
    <InfoContainer>
      <FormContainer onSubmit={(e) => e.preventDefault()}>
        <StyledTitle text="Ci siamo quasi!" style={{ marginBottom: '35px' }} />
        {[
          'Dai un titolo al tuo progetto e descrivilo brevemente.',
          'Se hai un documento di progetto o vuoi inviarci il tuo “brief” puoi allegare uno o più file che secondo te sono utili per confermare la quotazione.',
          'L’investimento sotto riportato è riferito alla configurazione base, se quanto descritto rientrerà nella configurazione base sarà nostro compito confermarti l’importo sotto stimato.',
          'Clicca su Salva e poi potrai valutare se inviarci la quotazione o se riprendere il tuo preventivo in seguito.',
        ].map((d) => (
          <Description key={d} text={d} style={{ width: '100%' }} />
        ))}
        <ControlledForm initialValues={initialValues} onChange={innerOnChange}>
          <br />
          <ControlledField
            name="title"
            fieldName="title"
            size="large"
            label="title_label"
            placeholder="title_label"
            required
          />
          <br />
          {currentUser && currentUser.role !== 'user' && (
            <>
              <ControlledField
                name="customer_name"
                label="customer_name"
                placeholder="customer_name"
                fieldName="customer_name"
                size="large"
                required
              />
              <br />
              <ControlledField
                name="company_name"
                label="company_name"
                placeholder="company_name"
                fieldName="company_name"
                size="large"
                required
              />
              <br />
            </>
          )}
          <DescriptionControlledField
            rows={10}
            name="description"
            fieldName="short_description"
            size="large"
            label="short_description_label"
            placeholder="short_description_label"
          />
          <input
            ref={inputRef}
            name="attachment"
            multiple
            type="file"
            placeholder="title_label"
            hidden
            onChange={async (event) => {
              event.preventDefault()
              if (event.target.files && event.target.files.length) {
                for await (const file of event.target.files) {
                  enqueue({
                    base64: await toBase64(file),
                    title: file.name,
                    type: 'doc',
                  })
                }
              }
            }}
          />
        </ControlledForm>
        <br />
        <NavigationButton
          radius={50}
          outline
          text="add_attachment"
          size="small"
          onClick={() => {
            if (inputRef && inputRef.current) {
              inputRef.current.click()
            }
          }}
          style={{ justifySelf: 'flex-start', marginBottom: '20px' }}
        />
        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
          {values.attachments?.map((a) => (
            <div key={a.id} style={{ position: 'relative', margin: '0 30px' }}>
              <AttachmentButton
                radius={50}
                iconLeft="file"
                tooltip="show_attachment"
                onClick={() => {
                  const link = document.createElement('a')
                  link.href = a.url
                  link.target = '_blank'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              />
              <DeleteAttachmentButton
                size="icon"
                iconLeft="exit"
                color="danger"
                onClick={() => {
                  setValues((v) => ({
                    ...v,
                    attachments: (v.attachments ?? []).filter(
                      (attachment) => attachment.id !== a.id
                    ),
                  }))
                }}
              />
              <Label text={ellipse(a.title)} style={{ position: 'relative', top: '-30px' }} />
            </div>
          ))}
          {loadQueue.map((lm) => (
            <div key={lm.title} style={{ margin: '0 30px' }}>
              <AttachmentButton radius={50} iconLeft="loader" tooltip="loading" />
              <Label text={ellipse(lm.title)} />
            </div>
          ))}
        </div>
      </FormContainer>
    </InfoContainer>
  )
}

const AttachmentButton = styled(NavigationButton)`
  min-width: unset;
  justify-self: 'flex-start';
`

const DescriptionControlledField = styled(ControlledField)`
  > textarea {
    line-height: 2;
    padding-top: 16px;
  }
`

const FormContainer = styled.form`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  flex-grow: 1;
`

const DeleteAttachmentButton = styled(Button)`
  position: relative;
  top: -80px;
  right: -60px;
  border-radius: 20px;
`
