import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import LogoImg from '../../images/logo_dark.png'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { Link } from './components/Link'
import { LocalizedSubtitle } from '../../components/Typography'
import { LocalizedInput } from '../../components/ControlledForm'
import { LocalizedButton } from '../../components/Button'

export const PasswordOTP = () => {
  const { state } = useLocation<{ email?: string }>()
  const history = useHistory()
  const [code, setCode] = useState('')
  const [request, { status, data }] = useRequest(api.loginOTP)

  useEffect(() => {
    if (!state || !state.email) {
      history.push('/login')
    }
  }, [state, history])

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email: state.email!, otp: code })
      }}
    >
      {data && <Redirect to={{ pathname: '/update-password', state: { session: data } }} />}
      <Logo src={LogoImg} alt="Logo" />
      <LocalizedSubtitle text="recover_password_subtitle" style={{ marginBottom: '32px' }} />
      <LocalizedInput
        name="code"
        type="text"
        label=""
        placeholder="insert_code"
        size="large"
        value={code}
        onChange={setCode}
        style={{ marginBottom: '50px', width: '80%' }}
      />
      <LocalizedButton
        text="update_password_button"
        radius={50}
        loading={status === 'loading'}
        style={{ width: '192px', marginTop: '30px' }}
      />
      <Below>
        <Link to="/login" text="back_to_login" />
      </Below>
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  @media screen and (max-width: 799px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  padding-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Logo = styled.img`
  width: 10vw;
  object-fit: contain;
`

const Below = styled.div`
  margin-top: 64px;
  padding-bottom: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
`
