import styled from 'styled-components'
import React from 'react'
import { Icon } from '@duckma/react-ds'
import DarkLogo from '../images/logo_dark.png'
import { StepsProgress } from './StepsProgress'
import { useHistory } from 'react-router-dom'

type Props = {
  onBack?: () => void
  perc?: number
}

export const ConfiguratorHeader: React.FC<Props> = ({ onBack, perc }) => {
  const history = useHistory()
  return (
    <Content>
      {onBack ? (
        <BackIcon name="arrow-left" color="primary100" size="24px" onClick={() => onBack()} />
      ) : (
        <div />
      )}
      <div></div>
      <Logo src={DarkLogo} onClick={() => history.push('/')} />
      <div></div>
      <div></div>
      {perc !== undefined && <StepsBar perc={perc} />}
    </Content>
  )
}

const StepsBar = styled(StepsProgress)`
  grid-column-start: span 5;
  appearance: none;
  margin-top: 24px;
  width: 100%;
  height: 2px;
`

const Content = styled.div`
  display: grid;
  height: fit-content;
  grid-template-columns: 10vw 10vw 50vw 10vw 10vw;
  padding: 5vh 5vw;
  justify-items: center;
  align-items: center;
`

const Logo = styled.img`
  max-height: 32px;
  cursor: pointer;
`

const BackIcon = styled(Icon)`
  cursor: pointer;
`
