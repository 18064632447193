import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { RequestStatus } from './useRequest'
import { translate } from '../i18n/dashboard_labels'
import { useSession } from '../contexts/SessionContext'

export const useSuccessToast = (requestStatus: RequestStatus, msg: string, navigateTo?: string) => {
  const history = useHistory()
  const { resetDirty } = useSession()

  useEffect(() => {
    if (requestStatus === 'success') {
      toast(translate(msg), { type: 'success' })
      resetDirty()
      if (navigateTo) {
        history.push(navigateTo)
      }
    }
  }, [requestStatus, msg, navigateTo, resetDirty, history])
}
