import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useMedia } from 'react-media'
import { Icon, Button, useTheme } from '@duckma/react-ds'

import { StyledTitle, InfoContainer, StyledSubtitle } from './layouts'
import { useModalContext } from './contexts/ModalContext'
import { Item } from '../../../data/models'
import { LocalizedTypography } from '../../../components/Typography'
import { PersonalisationModal } from './PersonalisationModal'
import { FormattedPrice } from './FormattedPrice'
import { TotalPrices } from '../../../hooks/useTotalPrices'
import { translate } from '../../../i18n/dashboard_labels'

const mediaQueries = {
  small: '(max-width: 799px)',
  large: '(min-width: 800px)',
}

type CustomSelection = { id: string; description?: string }[]

export type Props = {
  title: string
  subtitle: string
  items: Item[]
  currentSelection?: CustomSelection
  totalPrices: TotalPrices
  multiplierPercent?: number
  onChange: (selection?: CustomSelection) => void
  global_info?: string
  noTranslate?: boolean
  allowPersonalized?: boolean
  multipleSelection?: boolean
  showPrices?: boolean
}

const alreadySelected = (currentSelection: CustomSelection | undefined = [], item: Item | null) =>
  item ? currentSelection.find((s) => s.id === item.id) : null

const getRequirentItems = (item: Item, items: Item[]) =>
  items.filter((i) => i.requires.find((ri) => ri.id === item.id))

function updateSelection(
  item: Item,
  multipleSelection: boolean,
  currentSelection: CustomSelection = [],
  requirentItems: Item[],
  description?: string,
  update: boolean = false
): CustomSelection {
  console.log({ description, update })
  const { id } = item
  const selected = alreadySelected(currentSelection, item)

  if ((update && selected) || (description === undefined && selected?.description)) {
    return Object.assign([], currentSelection, {
      [currentSelection.findIndex((cs) => cs.id === item.id)]: {
        id,
        description,
      },
    })
  }

  if (selected && !item.mandatory) {
    return currentSelection.filter(
      (s) =>
        s.id !== id &&
        // Automatically remove items that require this item
        requirentItems.find((ri) => ri.id === s.id) === undefined
    )
  }
  return [
    ...(multipleSelection ? currentSelection : []),
    // Automatically add all required items that aren't currently selected
    ...item.requires
      .filter((r) => !currentSelection.find((i) => i.id === r.id))
      .map(({ id }) => ({ id })),
    { id, description },
  ]
}

export function ListStepPage({
  title,
  subtitle,
  items,
  currentSelection,
  multiplierPercent,
  totalPrices,
  showPrices = true,
  onChange,
  noTranslate,
  allowPersonalized = false,
  multipleSelection = false,
}: Props) {
  const { setUrl } = useModalContext()
  const theme = useTheme()
  const matches = useMedia({ queries: mediaQueries })
  const [personalizingItem, setPersonalizingItem] = useState<null | Item>(null)
  const [description, setDescription] = useState<string>('')

  const onInfoClick = useCallback(
    (ev, url) => {
      ev.stopPropagation()
      setUrl(url)
    },
    [setUrl]
  )

  return (
    <InfoContainer>
      <PersonalisationModal
        open={personalizingItem !== null}
        editing={alreadySelected(currentSelection, personalizingItem) !== undefined}
        onChange={setDescription}
        customizationNotes={description}
        onClose={() => {
          setPersonalizingItem(null)
          setDescription('')
        }}
        onRemove={() => {
          if (!personalizingItem) {
            return
          }
          onChange(
            updateSelection(
              personalizingItem,
              allowPersonalized,
              currentSelection,
              getRequirentItems(personalizingItem, items),
              ''
            )
          )
          setPersonalizingItem(null)
          setDescription('')
        }}
        onContinue={() => {
          if (!personalizingItem) {
            return
          }
          const requirentItems = items.filter((i) =>
            i.requires.find((ri) => ri.id === personalizingItem.id)
          )
          onChange(
            updateSelection(
              personalizingItem,
              allowPersonalized,
              currentSelection,
              requirentItems,
              description,
              alreadySelected(currentSelection, personalizingItem) !== undefined
            )
          )
          setPersonalizingItem(null)
          setDescription('')
        }}
      />
      <div>
        <StyledTitle text={title} />
      </div>
      <StyledSubtitle text={subtitle} />
      <ItemsList>
        {items.map((item) => {
          const selected = alreadySelected(currentSelection, item)
          const requirentItems = getRequirentItems(item, items)
          return (
            <ItemCard
              key={item.id}
              selected={selected !== undefined}
              selectedColor={theme['primary100']}
              standardColor={theme['gray20']}
              canBeClicked={!allowPersonalized}
              allowPersonalization={allowPersonalized}
              onClick={() =>
                allowPersonalized
                  ? () => {}
                  : onChange(
                      updateSelection(item, multipleSelection, currentSelection, requirentItems)
                    )
              }
            >
              <img
                src={item.image.thumbnail_url ?? item.image.url}
                alt=""
                style={{
                  minWidth: '100px',
                  minHeight: '100px',
                  width: '10vmax',
                  height: '10vmax',
                  objectFit: 'contain',
                }}
              />
              <TitleContainer>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ItemTitle text={item.title} noTranslate={noTranslate} />
                  {item.details_url !== '' && !matches.small && (
                    <TopInfoIcon
                      name="info"
                      size="24px"
                      onClick={(ev) => onInfoClick(ev, item.details_url)}
                    />
                  )}
                </div>
                <ItemDescription
                  dangerouslySetInnerHTML={{ __html: item.short_description || '' }}
                />
                {showPrices ? (
                  <FormattedPrice
                    item={{ ...item, customization: false }}
                    multiplierPercent={multiplierPercent}
                    totalPrices={totalPrices}
                  />
                ) : (
                  <div />
                )}
              </TitleContainer>
              {matches.small &&
                (item.details_url !== '' ? (
                  <SideInfoIcon
                    name="info"
                    color="gray100"
                    size="24px"
                    onClick={() => setUrl(item.details_url || null)}
                  />
                ) : (
                  <div />
                ))}
              {allowPersonalized && (
                <ButtonsContainer horizontal={matches.small}>
                  <Button
                    text={translate('base_button')}
                    radius={24}
                    outline={!selected || selected.description !== undefined}
                    onClick={() => {
                      onChange(
                        updateSelection(item, allowPersonalized, currentSelection, requirentItems)
                      )
                    }}
                  />
                  <Button
                    text={translate('personalize_button')}
                    radius={24}
                    outline={!selected || !selected.description}
                    onClick={() => {
                      setPersonalizingItem(item)
                      if (selected) {
                        setDescription(selected?.description || '')
                      }
                    }}
                  />
                </ButtonsContainer>
              )}
            </ItemCard>
          )
        })}
      </ItemsList>
    </InfoContainer>
  )
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 16px;
`

const ItemsList = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

const ItemTitle = styled(LocalizedTypography)`
  font-size: 0.9em;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`

const ItemDescription = styled.div`
  /* display: inline-block; */
  font-size: 0.9em;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  color: #626b7e;
  /* min-width: 100%; */
`

const SideInfoIcon = styled(Icon)`
  cursor: pointer;
  align-self: center;
  * {
    fill: #ff7b00;
  }
`

const TopInfoIcon = styled(Icon)`
  cursor: pointer;
  align-self: center;
  margin-left: 0.7em;
  * {
    fill: #ff7b00;
  }
`

export const ItemCard = styled.div<{
  canBeClicked: boolean
  selected: boolean
  selectedColor: string
  standardColor: string
  allowPersonalization: boolean
}>`
  min-height: ${(props) => (props.allowPersonalization ? '20vh' : '15vh')};
  cursor: ${(props) => (props.canBeClicked ? 'pointer' : 'initial')};
  display: grid;
  grid-template-columns: auto 1fr minmax(8vw, 200px);
  grid-column-gap: 1vw;
  > :nth-child(4) {
    grid-area: 2 / 1 / 3 / 4;
  }
  @media screen and (min-width: 800px) {
    min-height: ${(props) => (props.allowPersonalization ? '180px' : '150px')};
    align-items: center;
    > :nth-child(4) {
      grid-area: initial;
    }
    grid-template-rows: 100%;
  }
  @media screen and (max-width: 320px) {
    > :nth-child(1) {
      visibility: hidden;
    }
  }
  > :nth-child(1) {
    padding: 0 1vw;
  }
  margin: 8px 0px;
  padding: 16px 8px;
  border-radius: 20px;
  border-style: solid;
  border-width: 2px;
  /* Remove blue overlay on android devices */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  background-color: white;
  ${(props) =>
    props.selected
      ? `
        border-color: ${props.selectedColor};
        box-shadow: 0 2px 8px 0 rgba(170, 169, 169, 0.47);
      `
      : `
        border-color: ${props.standardColor};
        box-shadow: 0 2px 8px 0 rgba(210, 210, 210, 0.41);
      `}
`

export const ButtonsContainer = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-flow: ${(props) => (props.horizontal ? 'row' : 'column')} nowrap;
  justify-content: space-evenly;
  margin: ${(props) => (props.horizontal ? '0' : '0 2vw')};
  > * {
    width: 100%;
    height: 48px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: ${(props) => (props.horizontal ? '2vh 2vw 0 2vw' : '1vh 0')};
  }
  ${(props) =>
    props.horizontal
      ? `
  > :nth-child(1) {
    margin-bottom: 0;
  }
  `
      : ''}
`
