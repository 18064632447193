import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, Icon } from '@duckma/react-ds'

import { ConfigurationCard } from './components/ConfigurationCard'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { YoutubeModal } from './YoutubeModal'
import { translate } from '../../i18n/dashboard_labels'
import { InfoModal } from '../configurator/components/InfoModal'
import { useSession } from '../../contexts/SessionContext'
import { Logo } from './components/Logo'
import { IconName } from '@duckma/react-ds/dist/assets/icons/GeneratedIcons'

export const HomePage: React.FC = () => {
  const { currentUser } = useSession()
  const history = useHistory()

  const [getProspects, { status, data }] = useRequest(api.getProspects)
  const [isYoutubeModalOpen, setYoutubeModalOpen] = useState(false)
  const [isExamplesModalOpen, setExamplesModalOpen] = useState(false)
  const [isConfiguratorModalOpen, setConfiguratorModalOpen] = useState(false)

  useEffect(() => {
    if (currentUser) {
      getProspects({
        order_by: 'created_at',
        order_direction: 'desc',
        page: 0,
        page_size: 4,
      })
    }
  }, [currentUser, getProspects])

  return (
    <>
      {isYoutubeModalOpen && (
        <YoutubeModal
          url="https://www.youtube.com/embed/qxRMn1wjVwY"
          onClose={() => setYoutubeModalOpen(false)}
        />
      )}
      <InfoModal
        url={isExamplesModalOpen ? 'https://l.duckma.com/configuratore' : null}
        onClose={() => setExamplesModalOpen(false)}
      />
      <InfoModal
        url={isConfiguratorModalOpen ? 'https://l.duckma.com/configuratore' : null}
        onClose={() => setConfiguratorModalOpen(false)}
      />
      <Page>
        <PageRow justify="space-between" marginH="40px" style={{ flexDirection: 'row' }}>
          <LogoArea>
            <Logo />
          </LogoArea>
          <ButtonsArea>
            {currentUser &&
              ((currentUser?.tax_code_or_vat_code?.length ?? 0) > 0 ? (
                <Icon
                  name="user-alt"
                  color="black"
                  size="24px"
                  style={{ padding: '10px', cursor: 'pointer' }}
                  onClick={() => history.push('/me')}
                />
              ) : (
                <div
                  role="tooltip"
                  data-microtip-position="bottom"
                  aria-label={translate('complete_profile')}
                >
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 44 44"
                    version="1.1"
                    onClick={() => history.push('/me')}
                    style={{ cursor: 'pointer' }}
                  >
                    <g
                      id="redesign-home"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="1" transform="translate(-1262.000000, -46.000000)">
                        <g id="Group-2" transform="translate(1262.000000, 46.000000)">
                          <g id="Group" fill="#333333" fill-rule="nonzero">
                            <g
                              id="icon/stroke/user-alt/user-alt-24"
                              transform="translate(10.000000, 10.000000)"
                            >
                              <path
                                d="M12.0516393,10.9 C16.8557204,10.9 20.2063845,13.5348697 21.9353749,18.59823 C22.4886387,20.2184564 21.6236917,21.9804171 20.0034642,22.5336777 C19.6809543,22.6438055 19.3424921,22.7 19.0016978,22.7 L5.02685678,22.7 C3.31478498,22.7 1.92688035,21.3120714 1.92688035,19.6 C1.92688035,19.2479469 1.98684909,18.898466 2.10421359,18.566552 C3.88775998,13.5225724 7.25818561,10.9 12.0516393,10.9 Z M12.0516393,13.1 C8.23341804,13.1 5.66360987,15.0996014 4.17836371,19.2999695 C4.14429047,19.3963307 4.12688035,19.497792 4.12688035,19.6 C4.12688035,20.0970509 4.5298225,20.5 5.02686854,20.5 L19.0016978,20.5 C19.1006383,20.5 19.1989016,20.4836854 19.2925337,20.4517128 C19.7629227,20.2910886 20.0140361,19.7795521 19.8534107,19.3091626 C18.4164211,15.1009285 15.871916,13.1 12.0516393,13.1 Z M12,1.9 C14.2643675,1.9 16.1,3.73563253 16.1,6 C16.1,8.26436747 14.2643675,10.1 12,10.1 C9.73563253,10.1 7.9,8.26436747 7.9,6 C7.9,3.73563253 9.73563253,1.9 12,1.9 Z M12,4.1 C10.950659,4.1 10.1,4.95065898 10.1,6 C10.1,7.04934102 10.950659,7.9 12,7.9 C13.049341,7.9 13.9,7.04934102 13.9,6 C13.9,4.95065898 13.049341,4.1 12,4.1 Z"
                                id="icon-user-alt"
                              ></path>
                            </g>
                          </g>
                          <circle id="Oval" fill="#CC3333" cx="31" cy="31" r="7"></circle>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              ))}
          </ButtonsArea>
        </PageRow>
        <PageRow marginH="30px">
          <TitleArea>
            <div>{translate('welcome_to')}</div>
            <div>{translate('mecha_configurator')}</div>
          </TitleArea>
          <WelcomeTextArea>
            <span dangerouslySetInnerHTML={{ __html: translate('home_text') }} />
          </WelcomeTextArea>
        </PageRow>
        <PageRow align="start" marginH="40px">
          <NewArea>
            <SmallTitle>{translate('new_configuration')}</SmallTitle>
            <NewButton
              text={translate('start')}
              iconLeft="add"
              iconRight="angle-right"
              onClick={() => history.push('/configurator')}
            />
            <InfoCard
              icon="video"
              title="video_tutorial"
              description="video_tutorial_description"
              onClick={() => setYoutubeModalOpen(true)}
            />
            <InfoCard
              icon="phone"
              title="book_call"
              description="book_call_description"
              onClick={() => {
                window.open('https://the.duckma.com/meetings/duckma/consulting')
              }}
            />
            <InfoCard
              icon="dashboard"
              title="configuration_examples"
              description="configuration_examples_description"
              onClick={() => setExamplesModalOpen(true)}
            />
            <InfoCard
              icon="file"
              title="similar_configurator"
              description="similar_configurator_description"
              onClick={() => setConfiguratorModalOpen(true)}
            />
          </NewArea>
          <LastArea>
            {currentUser ? (
              <>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <SmallTitle>{translate('last_configurations')}</SmallTitle>
                  <SmallButton text={translate('see_all')} />
                </div>
                <ConfigurationList>
                  {data &&
                    data.items.map((configuration) => (
                      <ConfigurationCard
                        key={configuration.id}
                        configuration={configuration}
                        height="15vh"
                      />
                    ))}
                  {status === 'success' && data && data.items.length === 0 && (
                    <NuancedText>{translate('no_configurations')}</NuancedText>
                  )}
                </ConfigurationList>
              </>
            ) : (
              <EmptyState>
                <svg
                  width="143px"
                  height="102px"
                  viewBox="0 0 143 102"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="1" transform="translate(-862.000000, -446.000000)" fill-rule="nonzero">
                      <g id="Group-2" transform="translate(724.000000, 446.000000)">
                        <g
                          id="undraw_processing_thoughts_d8ha"
                          transform="translate(138.000000, 0.000000)"
                        >
                          <circle
                            id="Oval"
                            fill="#E6E6E6"
                            cx="83.596629"
                            cy="47.5697529"
                            r="36.0750001"
                          ></circle>
                          <rect
                            id="Rectangle"
                            fill="#3F3D56"
                            x="17.1016288"
                            y="27.4847528"
                            width="59.6700002"
                            height="1"
                          ></rect>
                          <rect
                            id="Rectangle"
                            fill="#3F3D56"
                            x="17.1016288"
                            y="38.2097529"
                            width="117.195"
                            height="1"
                          ></rect>
                          <path
                            d="M98.2216271,76.6247452 C78.2223197,76.6247452 61.9516269,60.3540524 61.9516269,40.3547451 C61.9516269,20.3554378 78.2223197,4.08474496 98.2216271,4.08474496 C118.220934,4.08474496 134.491627,20.3554378 134.491627,40.3547451 C134.491627,60.3540524 118.220934,76.6247452 98.2216271,76.6247452 Z M98.2216271,4.47474496 C78.437317,4.47474496 62.3416269,20.570435 62.3416269,40.3547451 C62.3416269,60.1390551 78.437317,76.2347452 98.2216271,76.2347452 C118.005937,76.2347452 134.101627,60.1390551 134.101627,40.3547451 C134.101627,20.570435 118.005939,4.47474496 98.2216271,4.47474496 Z"
                            id="Shape"
                            fill="#3F3D56"
                          ></path>
                          <circle
                            id="Oval"
                            fill="#CC3333"
                            cx="94.8753139"
                            cy="4.76198581"
                            r="4.76198581"
                          ></circle>
                          <circle
                            id="Oval"
                            fill="#FF6584"
                            cx="62.0877018"
                            cy="37.8023862"
                            r="3.08128471"
                          ></circle>
                          <circle
                            id="Oval"
                            fill="#CC3333"
                            cx="135.646476"
                            cy="37.8530608"
                            r="7.03703132"
                          ></circle>
                          <path
                            d="M36.7309821,65.619109 L15.378677,65.619109 L15.378677,66.009109 L36.7309821,66.009109 C38.9200724,66.009109 40.6946808,67.7837173 40.6946808,69.9728077 C40.6946808,72.161898 38.9200724,73.9365064 36.7309821,73.9365064 L4.42126231,73.9365064 C2.23217196,73.9365064 0.457563601,72.161898 0.457563601,69.9728077 C0.457563601,67.7837173 2.23217196,66.009109 4.42126231,66.009109 L5.62868282,66.009109 L5.62868282,65.619109 L4.42126231,65.619109 C2.01678091,65.619109 0.0675636002,67.5683263 0.0675636002,69.9728077 C0.0675636002,72.3772891 2.01678091,74.3265064 4.42126231,74.3265064 L36.7309821,74.3265064 C39.1354635,74.3265064 41.0846808,72.3772891 41.0846808,69.9728077 C41.0846808,67.5683263 39.1354635,65.619109 36.7309821,65.619109 Z"
                            id="Path"
                            fill="#3F3D56"
                          ></path>
                          <path
                            d="M36.7309821,79.074109 L15.378677,79.074109 L15.378677,79.464109 L36.7309821,79.464109 C38.9200724,79.464109 40.6946808,81.2387174 40.6946808,83.4278077 C40.6946808,85.6168981 38.9200724,87.3915064 36.7309821,87.3915064 L4.42126231,87.3915064 C2.23217196,87.3915064 0.457563601,85.6168981 0.457563601,83.4278077 C0.457563601,81.2387174 2.23217196,79.464109 4.42126231,79.464109 L5.62868282,79.464109 L5.62868282,79.074109 L4.42126231,79.074109 C2.01678091,79.074109 0.0675636002,81.0233263 0.0675636002,83.4278077 C0.0675636002,85.8322891 2.01678091,87.7815064 4.42126231,87.7815064 L36.7309821,87.7815064 C39.1354635,87.7815064 41.0846808,85.8322891 41.0846808,83.4278077 C41.0846808,81.0233263 39.1354635,79.074109 36.7309821,79.074109 Z"
                            id="Path"
                            fill="#3F3D56"
                          ></path>
                          <path
                            d="M36.7309821,92.529109 L15.378677,92.529109 L15.378677,92.919109 L36.7309821,92.919109 C38.9200724,92.919109 40.6946808,94.6937174 40.6946808,96.8828077 C40.6946808,99.0718981 38.9200724,100.846506 36.7309821,100.846506 L4.42126231,100.846506 C2.23217196,100.846506 0.457563601,99.0718981 0.457563601,96.8828077 C0.457563601,94.6937174 2.23217196,92.919109 4.42126231,92.919109 L5.62868282,92.919109 L5.62868282,92.529109 L4.42126231,92.529109 C2.01678091,92.529109 0.0675636002,94.4783263 0.0675636002,96.8828077 C0.0675636002,99.2872892 2.01678091,101.236506 4.42126231,101.236506 L36.7309821,101.236506 C39.1354635,101.236506 41.0846808,99.2872892 41.0846808,96.8828077 C41.0846808,94.4783263 39.1354635,92.529109 36.7309821,92.529109 Z"
                            id="Path"
                            fill="#3F3D56"
                          ></path>
                          <path
                            d="M8.03877752,64.5887913 C7.40597216,64.599365 6.89851954,65.1153677 6.89851954,65.7482614 C6.89851954,66.381155 7.40597216,66.8971577 8.03877752,66.9077314 L13.1254929,66.9077314 C13.7644807,66.9162431 14.2899229,66.4061986 14.3004142,65.7672403 C14.3109056,65.128282 13.8024914,64.6012622 13.1635686,64.5887913 C13.1508754,64.5885768 13.1381835,64.5885768 13.1254929,64.5887913 L8.03877752,64.5887913 Z"
                            id="be0e5baf-e5ec-4f16-9492-7d4921bb2c9b"
                            fill="#CC3333"
                          ></path>
                          <path
                            d="M8.03877752,78.2387914 C7.40597216,78.2493651 6.89851954,78.7653677 6.89851954,79.3982614 C6.89851954,80.0311551 7.40597216,80.5471577 8.03877752,80.5577314 L13.1254929,80.5577314 C13.7644807,80.5662431 14.2899229,80.0561987 14.3004142,79.4172404 C14.3109056,78.778282 13.8024914,78.2512622 13.1635686,78.2387914 C13.1508754,78.2385769 13.1381835,78.2385769 13.1254929,78.2387914 L8.03877752,78.2387914 Z"
                            id="f5faaf73-4e1d-4e3d-a937-9f255bff3911"
                            fill="#FF6584"
                          ></path>
                          <path
                            d="M8.03877752,91.4987914 C7.40585508,91.5092066 6.89822555,92.0252582 6.89822555,92.6582663 C6.89822555,93.2912744 7.40585508,93.8073261 8.03877752,93.8177412 L13.1254929,93.8177412 C13.7653705,93.827549 14.2922346,93.3171499 14.3027408,92.6772834 C14.313247,92.0374169 13.8034233,91.509996 13.1635686,91.4987914 C13.1508754,91.4985769 13.1381835,91.4985769 13.1254929,91.4987914 L8.03877752,91.4987914 Z"
                            id="bdcbecaa-58f8-42a6-983f-c76dd899917c"
                            fill="#3F3D56"
                          ></path>
                          <path
                            d="M6.02122172,68.8133338 C5.38837081,68.8238443 4.88084861,69.3398656 4.88084861,69.9728038 C4.88084861,70.605742 5.38837081,71.1217632 6.02122172,71.1322738 L35.0929392,71.1322738 C35.507852,71.1401247 35.8953328,70.9256607 36.1089951,70.5699042 C36.3226574,70.2141476 36.3299278,69.7713347 36.1280595,69.4087557 C35.9261913,69.0461767 35.5459599,68.819108 35.1310129,68.8133338 C35.1183223,68.8131167 35.1056311,68.8131167 35.0929392,68.8133338 L6.02122172,68.8133338 Z"
                            id="bbcd49d7-9efc-41e7-bbf8-c6009cf796dd"
                            fill="#E6E6E6"
                          ></path>
                          <path
                            d="M6.02122172,82.2683338 C5.38837081,82.2788444 4.88084861,82.7948656 4.88084861,83.4278038 C4.88084861,84.060742 5.38837081,84.5767632 6.02122172,84.5872738 L35.0929392,84.5872738 C35.507852,84.5951247 35.8953328,84.3806608 36.1089951,84.0249042 C36.3226574,83.6691476 36.3299278,83.2263347 36.1280595,82.8637557 C35.9261913,82.5011767 35.5459599,82.274108 35.1310129,82.2683338 C35.1183223,82.2681167 35.1056311,82.2681167 35.0929392,82.2683338 L6.02122172,82.2683338 Z"
                            id="b24c44c6-9087-448d-956a-9230903e4c37"
                            fill="#E6E6E6"
                          ></path>
                          <path
                            d="M6.02122172,95.7233338 C5.38837081,95.7338444 4.88084861,96.2498657 4.88084861,96.8828038 C4.88084861,97.515742 5.38837081,98.0317633 6.02122172,98.0422738 L35.0929392,98.0422738 C35.507852,98.0501248 35.8953328,97.8356608 36.1089951,97.4799042 C36.3226574,97.1241477 36.3299278,96.6813348 36.1280595,96.3187558 C35.9261913,95.9561768 35.5459599,95.729108 35.1310129,95.7233338 C35.1183223,95.723118 35.1056311,95.723118 35.0929392,95.7233338 L6.02122172,95.7233338 Z"
                            id="f49c9183-be6f-4e21-8191-a3b3cb38d75e"
                            fill="#E6E6E6"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <StyledSubtitle>{translate('login_cta')}</StyledSubtitle>
                <SmallButton
                  text={translate('register')}
                  onClick={() => history.push('/register')}
                  style={{ marginTop: '47px', marginBottom: '30px' }}
                />
                <SmallButton
                  text={translate('access')}
                  outline
                  onClick={() => history.push('/login')}
                />
              </EmptyState>
            )}
          </LastArea>
        </PageRow>
      </Page>
    </>
  )
}

const Page = styled.div`
  font-family: 'Raleway', serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 90vw;
  padding: 0 5vw;
  @media screen and (max-width: 800px) {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }
`

const LogoArea = styled.div`
  grid-area: logo;
  display: flex;
  align-items: center;
`

const ButtonsArea = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TitleArea = styled.div`
  min-width: 480px;
  margin-right: 60px;
  font-family: 'Raleway', serif;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  > :first-child {
    font-weight: 300;
    font-size: 24px;
    line-height: 48px;
    color: #828894;
  }
  > :last-child {
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    color: #828894;
  }
  @media screen and (max-width: 800px) {
    margin: 0 0 0 20px;
    width: calc(100% - 20px);
    min-width: initial;
    justify-content: flex-start;
    > :first-child {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;
      color: #828894;
    }
    > :last-child {
      font-weight: 900;
      font-size: 26px;
      line-height: 32px;
      color: #828894;
    }
  }
`

const WelcomeTextArea = styled.div`
  font-family: 'Raleway', serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #b9bdc6;
  display: flex;
  align-items: center;
  @media screen and (max-width: 800px) {
    margin: 15px 20px 0 20px;
    font-size: 16px;
    line-height: 20px;
  }
`

const NewArea = styled.div`
  border-radius: 23px;
  background-color: #f4f4f6;
  padding: 40px 40px 20px 40px;
  min-width: 400px;
  @media screen and (max-width: 800px) {
    min-width: calc(100% - 80px);
  }
`

const LastArea = styled.div`
  width: 100%;
  margin-left: 60px;
  @media screen and (max-width: 800px) {
    margin: 0;
    margin-top: 40px;
    padding: 0 20px;
    width: calc(100% - 40px);
  }
`

const SmallTitle = styled.span`
  font-family: 'Raleway', serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  width: 100%;
  flex-grow: 1;
`

const NewButton = styled(Button)`
  font-family: 'Raleway', serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  border-radius: 21px;
  padding: 37px;
  display: flex;
  justify-content: left;
  box-shadow: 0 2px 17px 0 rgba(210, 210, 210, 0.41);
  &:nth-child(2) {
    > :nth-child(3) {
      justify-self: flex-end;
      margin-left: auto !important;
    }
  }
  margin-top: 39px;
  margin-bottom: 72px;
  @media screen and (max-width: 800px) {
    margin-top: 24px;
    margin-bottom: 39px;
  }
`

const InfoCard: React.FC<{
  icon: IconName
  title: string
  description: string
  onClick?: () => void
}> = ({ icon, title, description, onClick }) => (
  <StyledInfoCard onClick={onClick}>
    <Icon name={icon} color="black" size="24px" />
    <div>
      <div>{translate(title)}</div>
      <div>{translate(description)}</div>
    </div>
  </StyledInfoCard>
)
const StyledInfoCard = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  background-color: #fff;
  border-radius: 21px;
  padding: 15px 17px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
  &:hover {
    background-color: #ddd;
    opacity: 0.8;
  }

  div > {
    display: flex;
    flex-flow: column nowrap;
    &:nth-child(1) {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      color: #222;
      margin-left: 15px;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #828894;
      margin-left: 15px;
    }
  }
  box-shadow: 0 2px 17px 0 rgba(210, 210, 210, 0.41);
`

const ConfigurationList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
`

const NuancedText = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #b9bdc6;
`

const PageRow = styled.div<{ marginH?: string; justify?: string; align?: string }>`
  width: 100%;
  display: flex;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  margin: ${(props) => (props.marginH ? props.marginH : '30px')} 0;
  ${(props) => (props.justify ? `justify-content: ${props.justify};` : '')}
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 10px 0;
  }
`

const SmallButton = styled(Button)`
  flex: 0;
  white-space: nowrap;
  font-family: 'Raleway', serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  border-radius: 28px;
  padding: 10px 20px;
`

const EmptyState = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  > button {
    max-width: 250px;
  }
  > :nth-child(1) {
    margin-top: 110px;
    margin-bottom: 43px;
    padding: 0;
  }
  @media screen and (max-width: 800px) {
    text-align: center;
    margin-bottom: 30px;
    > :nth-child(1) {
      margin-top: 20px;
      margin-bottom: 23px;
      justify-content: flex-start;
    }
  }
`

const StyledSubtitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #b9bdc6;
`
