import React, { useEffect } from 'react'
import { ThemeContext } from '@duckma/react-ds'
import moment from 'moment'
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { theme } from './styles/theme'
import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { storage } from './data/storage'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayout } from './components/MainLayout'
import { ProfilePage } from './modules/profile/Profile'
import { Configurator } from './modules/configurator/Configurator'
import { HomePage } from './modules/configurations/HomePage'
import { Configurations } from './modules/configurations/Configurations'
import { analytics } from './data/analytics'
import { useRequest } from './hooks/useRequest'
import { api } from './data/api'
import { SessionProvider } from './contexts/SessionContext'
import { RegisterPage } from './modules/auth/Register'
import { VerifyPage } from './modules/auth/Verify'

const PrivateRoute = ({
  component: Component,
  header,
  ...rest
}: {
  component: React.ReactType
  header: React.ComponentProps<typeof MainLayout>
  [key: string]: any
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getToken() !== null ? (
          <MainLayout {...header}>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

const PrivateCustomRoute = ({
  component: Component,
  ...rest
}: {
  component: React.ReactType
  [key: string]: any
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getToken() !== null ? (
          <MainLayout fullScreen>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

const PageChangeListener: React.FC = () => {
  const history = useHistory()
  history.listen((location) => {
    analytics.pageChangeEvent(location.pathname)
  })
  return null
}

const UpdateChecker: React.FC = () => {
  const [check, { data }] = useRequest(api.getPWAVersion, { disableErrorMessage: true })
  useEffect(() => {
    // check({})
    const intervalId = setInterval(() => {
      check({})
    }, 1000 * 60)
    return () => clearInterval(intervalId)
  }, [check])
  useEffect(() => {
    if (data && moment(data.version).isAfter(storage.getLastUpdated())) {
      console.log('Updating')
      storage.saveLastUpdated()
      setTimeout(() => {
        window.location.reload(true)
      }, 0)
    } else console.log('Not updating.')
  }, [data])
  return null
}

function App() {
  return (
    <ThemeContext.Provider value={theme}>
      <SessionProvider>
        <Router>
          <PageChangeListener />
          <UpdateChecker />
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/password-otp" component={PasswordOTP} />
            <Route path="/verify" component={VerifyPage} />
            <Route path="/update-password" component={UpdatePassword} />
            <Route exact path="/" component={HomePage} />
            <Route path="/configurator" component={Configurator} />
            <PrivateRoute path="/me" component={ProfilePage} header={{ title: 'profile' }} />
            <PrivateCustomRoute path="/configurations/:id" component={Configurator} />
            <PrivateRoute
              path="/configurations"
              component={Configurations}
              header={{ title: 'configurations' }}
            />
          </Switch>
        </Router>
      </SessionProvider>
    </ThemeContext.Provider>
  )
}

export default App
