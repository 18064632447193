import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import qs from 'qs'

import { api } from '../../data/api'
import { ConfiguratorHeader } from '../../components/ConfiguratorHeader'
import { useHistory, useLocation } from 'react-router-dom'
import { useRequest } from '../../hooks/useRequest'
import { LocalizedDescription, LocalizedTitle } from '../../components/Typography'

type Params = { token?: string; redirectUrl?: string }

export const VerifyPage: React.FC = () => {
  const history = useHistory()
  const { search } = useLocation()
  const [validate, { status: validateStatus }] = useRequest(api.validateUser)

  const { token, redirectUrl } = useMemo(
    () => qs.parse(search.length > 0 ? search.substr(1) : '') as Params,
    [search]
  )

  useEffect(() => {
    if (token && token.length > 20) {
      validate({ token })
    }
  }, [token, validate])

  useEffect(() => {
    if (validateStatus === 'success') {
      if (redirectUrl) {
        history.push(redirectUrl)
      } else {
        history.push('/')
      }
    }
  }, [history, redirectUrl, validateStatus])

  return (
    <PageContainer>
      <ConfiguratorHeader perc={1} />
      <FormContainer>
        {validateStatus === 'loading' && <LocalizedTitle text="validating" />}
        {validateStatus === 'error' && <LocalizedTitle text="error_validating" />}
        {validateStatus === 'idle' && <LocalizedTitle text="check_emails" />}
        {validateStatus === 'idle' && <LocalizedDescription text="check_emails_text" />}
      </FormContainer>
    </PageContainer>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: calc(100%-64px);
  max-width: 500px;
  padding: 0 32px;
  > * {
    margin: 10px 0;
  }
`

const PageContainer = styled.div`
  background-color: #fdfdfd;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`
