import React from 'react'
import styled from 'styled-components'
import { Icon } from '@duckma/react-ds'

import { useHistory } from 'react-router-dom'
import { translate } from '../i18n/dashboard_labels'
import { LocalizedHeaderTitle } from './Typography'

type Props = {
  title: string
  backLink?: { to: string; text: string }
  button?: React.ReactNode
  dirty?: boolean
}

export const Header: React.FC<Props> = ({ title, backLink, button, dirty }) => {
  const history = useHistory()

  return (
    <Container>
      {backLink && (
        <BackContainer
          onClick={() => {
            if (dirty) {
              return (window.location.href = backLink.to)
            }
            history.push(backLink.to)
          }}
        >
          <Icon name="arrow-left" color="gray50" size="18px" />
        </BackContainer>
      )}
      <TitleContainer>
        <StyledTitle color={'gray100'}>{translate(title)}</StyledTitle>
        <div style={{ flexGrow: 1 }} />
        <div>{button}</div>
      </TitleContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 100px);
  padding: 0;
  margin: 0;
  border: none;
  padding-left: 50px;
  padding-right: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-between;
  align-items: center;
`

const StyledTitle = styled(LocalizedHeaderTitle)`
  display: inline-block;
  margin: 0;
`

const BackContainer = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
