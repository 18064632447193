import { useState, useCallback, useMemo } from 'react'

import { translateError } from '../i18n/errors'
import { toast } from 'react-toastify'

export type RequestStatus = 'idle' | 'loading' | 'success' | 'error'
type RequestState<T> = {
  status: RequestStatus
  data: T | null
  error: string | null
}
type Options = {
  disableErrorMessage?: boolean
}
const defaultOptions: Options = {
  disableErrorMessage: false,
}
export const useRequest = <T, P>(
  fetchFn: (params: P) => Promise<T>,
  options: Options = defaultOptions
) => {
  const [state, setState] = useState<RequestState<T>>({
    status: 'idle',
    data: null,
    error: null,
  })

  const request = useCallback(
    (params: P) => {
      setState((state) => ({ ...state, status: 'loading' }))
      fetchFn(params)
        .then((data: T) => {
          setState({ data, error: null, status: 'success' })
        })
        .catch((error: Error) => {
          const text = translateError(error.message)
          setState({ data: null, error: text, status: 'error' })
          if (!options.disableErrorMessage) {
            toast(text, { type: 'error' })
          }
        })
    },
    [fetchFn, options.disableErrorMessage]
  )

  return useMemo(
    () =>
      [request, state, () => setState({ data: null, error: null, status: 'idle' })] as [
        (params: P) => void,
        RequestState<T>,
        () => void
      ],
    [request, state]
  )
}
