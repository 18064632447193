import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link as _Link } from 'react-router-dom'
import { ThemeContext, ColorName } from '@duckma/react-ds'
import { translate } from '../../../i18n/dashboard_labels'

type Props = {
  to: string
  text: string
  color?: ColorName
  style?: {}
  className?: string
}

export const Link = ({ to, text, color = 'primary100', style, className }: Props) => {
  const theme = useContext(ThemeContext)

  return (
    <StyledLink to={to} color={theme[color]} style={style} className={className}>
      {translate(text)}
    </StyledLink>
  )
}

type StyledProps = { color: string }
const StyledLink = styled(_Link)<StyledProps>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.color};
  :visited {
    color: ${(props) => props.color};
  }
  :hover {
    opacity: 0.8;
  }
`
