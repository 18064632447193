import React from 'react'
import styled from 'styled-components'
import { ColorName } from '@duckma/react-ds'
import { theme } from '../styles/theme'

declare type LoaderProps = {
  backgroundColor: ColorName
  foregroundColor: ColorName
  size: string
  className?: string
}

export const Loader: React.FC<LoaderProps> = ({
  backgroundColor,
  foregroundColor,
  size,
  className,
}) => {
  return (
    <Container className={className}>
      <StyledLoader
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        size={size}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`

const StyledLoader = styled.div<{
  backgroundColor: ColorName
  foregroundColor: ColorName
  size: string
}>`
  align-self: center;
  border: 8px solid ${(props) => theme[props.backgroundColor]};
  border-top: 8px solid ${(props) => theme[props.foregroundColor]};
  border-radius: 50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
