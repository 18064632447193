import { User, Prospect, Item, Media, FinancialPlan, Provider } from './models'
import { storage } from './storage'
import { init as firebaseInit } from '../libs/firebase'
import _ from 'lodash'

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | number },
    useToken: boolean = true
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri += '?' + _.map(params, (value, key) => `${key}=${value}`).join('&')
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(useToken),
    }).then((res) => this.handleResponse<T>(res))
  }

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`)
    }
    if (response.status === 401) {
      this.clearSession()
      window.location.reload()
    }
    return response.json().then((json: { data: T | null; error: null | { code: string } }) => {
      if (response.status !== 200) {
        throw new Error(json.error!.code || 'invalid_server_response')
      }
      return json.data!
    })
  }

  private getHeaders = (useToken: boolean) => {
    const token = storage.getToken()
    return {
      ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
      platform: 'application',
      'User-Language': navigator?.language,
      'Content-Type': 'application/json',
    }
  }

  private get = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('GET', path, undefined, params, useToken)

  private delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('DELETE', path, undefined, params, useToken)

  private post = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('POST', path, body, undefined, useToken)

  private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PUT', path, body, undefined, useToken)

  private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PATCH', path, body, undefined, useToken)

  private cleanParams = (params: { [key: string]: string | number | null | undefined }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== '')
      .mapValues((param) => String(param))
      .value()

  private persistSession = (res: Session) => {
    storage.saveToken(res.token)
    storage.saveUser(res.user)
    firebaseInit()
    return res
  }

  public clearSession = () => {
    storage.cleanAll()
  }

  public login = ({ email, password }: { email: string; password: string }) =>
    this.post<AuthResponse>('/auth/login', { email, password, provider: 'email' }, false).then(
      this.persistSession
    )

  public googleLogin = ({ token_id }: { token_id: string }) =>
    this.post<AuthResponse>('/auth/login', { token_id, provider: 'google' }, false).then(
      this.persistSession
    )

  public register = (body: RegisterUser) => this.post<AuthResponse>('/auth/signup', body)

  public recoverPassword = ({ email }: { email: string }) =>
    this.post<{}>('/auth/otp/request', { email }, false)

  public loginOTP = (body: { email: string; otp: string }) =>
    this.post<AuthResponse>('/auth/otp/login', body, false).then(this.persistSession)

  public updatePassword = ({ password, session }: { password: string; session?: Session }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session)
    }
    return this.put<User>('/me/password', { password }).catch((err) => {
      if (session) {
        this.clearSession()
      }
      throw err
    })
  }

  public validateUser = (body: { token: string }) =>
    this.post<AuthResponse>(`/auth/otp/activate`, body).then(this.persistSession)

  public getMe = () =>
    this.get<User>('/me', {}).then((user) => {
      storage.saveUser(user)
      return user
    })

  public updateMe = (body: UpdateUser) => this.patch<User>('/me', body)
  public createMedia = (body: CreateMedia) => this.post<Media>('/media', body)

  public updateMedia = ({ id, body }: { id: string; body: UpdateMedia }) =>
    this.patch<Media>(`/media/${id}`, body)

  public deleteMedia = (params: { id: string }) => this.delete<Media>(`/media/${params.id}`, {})
  public getProspects = (params: ListParams) =>
    this.get<PaginatedResponse<Prospect>>('/prospects/mine', this.cleanParams(params))

  public getProspect = (params: { id: string }) => this.get<Prospect>(`/prospects/${params.id}`, {})

  public saveProspect = (body: CreateProspect) =>
    this.post<Prospect>(`/prospects`, {
      ...body,
      items: body.items.map((i) => _.pick(i, ['id', 'customization_notes'])),
    })

  public patchProspect = (params: {
    id: string
    update: Partial<CreateProspect> & { financial_plan?: FinancialPlan }
  }) =>
    this.patch<Prospect>(`/prospects/${params.id}`, {
      ...params.update,
      ...(params.update.items
        ? {
            items: params.update.items.map(
              ({
                id,
                title,
                category,
                price,
                price_model,
                price_recurrence,
                customization,
                customization_notes,
              }) => ({
                id,
                title,
                category,
                price,
                price_model,
                price_recurrence,
                customization,
                customization_notes,
              })
            ),
          }
        : {}),
    })

  public getItems = (params: ListParams) =>
    this.get<PaginatedResponse<Item>>(`/items/visible`, this.cleanParams(params))

  public getFinancialPlanConfig = () =>
    this.get<{
      payment_upfront: number[]
      duration_months: { duration: number; percentage: number }[]
    }>(`/prospects/financial_plan_config`, {})

  public updateFCMToken = ({ token }: { token: string }) =>
    this.patch<{}>(`/me/fcm_token`, { token })

  public getPWAVersion = () => this.get<{ version: string }>(`/system/pwa_version`, {})
}

export type Session = {
  token: string
  user: User
}

type AuthResponse = {
  token: string
  user: User
}

export type PaginatedResponse<T> = {
  items: T[]
  pagination: { current_page: number; total_pages: number; page_size: number }
}

export type ListParams = {
  search_text?: string
  order_by: string
  order_direction: 'asc' | 'desc'
  page: number
  page_size?: number
  [key: string]: string | number | undefined
}

export type RegisterUser = Pick<
  User,
  'email' | 'first_name' | 'last_name' | 'phone_number' | 'business_name'
> & { password: string; provider: Provider; createProspectData?: CreateProspect }
export type UpdateUser = Partial<
  Pick<
    User,
    'first_name' | 'last_name' | 'business_name' | 'phone_number' | 'type' | 'tax_code_or_vat_code'
  >
>

export type MediaUploadResponse = { file_name: string; url: string }
export type MediaUploadInput = { file_name: string; encoded_file: string }

export type CreateProspect = Omit<
  Prospect,
  | 'id'
  | 'user'
  | 'created_at'
  | 'attachments'
  | 'offer_attachments'
  | 'financial_plan'
  | 'multiplier_percent'
> & { attachments: string[] }

export type CreateMedia = Pick<Media, 'title' | 'type'> & { base64: string }
export type UpdateMedia = Omit<CreateMedia, 'base64' | 'image'>

export const api = new API()
