import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ColorName, Icon, ThemeContext, Typography } from '@duckma/react-ds'
import ReactModal from 'react-modal'
import { useMedia } from 'react-media'

type Props = {
  open?: boolean
  color?: ColorName
  onClose?: () => void
  className?: string
}

ReactModal.setAppElement('#root')

const mediaQueries = {
  small: '(max-width: 799px)',
  large: '(min-width: 800px)',
}

export const ImmersiveModal: React.FC<Props> = ({
  open = false,
  color = 'gray50',
  onClose,
  className,
  children,
}) => {
  const theme = useContext(ThemeContext)
  const matches = useMedia({ queries: mediaQueries })

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'initial'
  }, [open])

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.40)',
          zIndex: 10,
        },
        content: {
          padding: 0,
          margin: 0,
          backgroundColor: theme[color],
          ...(matches.small
            ? {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
              }
            : {}),
        },
      }}
      className={className}
    >
      {children}
      <Header textColor={theme['black']}>
        <ModalTitle>Chiudi</ModalTitle>
        <ExitIcon name="exit" size="24px" color="black" onClick={onClose} />
      </Header>
    </ReactModal>
  )
}

const ModalTitle = styled(Typography)`
  font-family: 'Roboto-Light', sans-serif;
  font-weight: 200;
  color: #222;
  font-size: 1em;
`

const Header = styled.div<{ textColor: string }>`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  box-sizing: border-box;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: 18px;
  color: ${(props) => props.textColor};
  user-select: none;
`

const ExitIcon = styled(Icon)`
  cursor: pointer;
  user-select: none;
  border: solid 1px;
  border-radius: 50%;
  padding: 4px;
  margin-left: 8px;
`
