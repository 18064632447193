import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Modal } from '@duckma/react-ds'

export const YoutubeModal: React.FC<{
  url: string | null
  onClose: () => void
}> = ({ url, onClose }) => {
  useEffect(() => {
    document.body.style.overflow = url !== null ? 'hidden' : 'initial'
  }, [url])

  return (
    <StyledModal open={url !== null} onClose={onClose} color="white">
      <HtmlContainer
        dangerouslySetInnerHTML={{
          __html: `<iframe width="100%" height="100%" src="${url}" frameborder="0" allow="accelerometer; autoplay="1"; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
        }}
      />
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 5vh;
  left: 10vw;
  right: 10vw;
  bottom: 5vh;
  outline: none;
  border: none;
  border-radius: 10px 10px 0 0;
  @media screen and (max-width: 800px) {
    top: 20vh;
    left: 0vw;
    right: 0vw;
    bottom: 20vh;
  }
  > * {
    z-index: 2;
  }
`

const HtmlContainer = styled.div`
  /* height: calc(100% - 64px);
  padding-top: 64px; */
  height: calc(90vh - 63px);
  @media screen and (max-width: 800px) {
    height: calc(60vh - 64px);
  }
`
