import { Button } from '@duckma/react-ds'
import React from 'react'
import { translateTextProp } from './Typography'

declare type LocalizedButtonProps = Omit<
  React.ComponentProps<typeof Button>,
  'text' | 'tooltip'
> & {
  text?: string | string[]
  tooltip?: string | string[]
  noTranslate?: boolean
}

export const LocalizedButton = (props: LocalizedButtonProps) => {
  return (
    <Button
      {...props}
      text={props.text && translateTextProp(props.text, props.noTranslate)}
      tooltip={props.tooltip ? translateTextProp(props.tooltip, props.noTranslate) : undefined}
      style={{
        ...props.style,
        whiteSpace: 'nowrap',
      }}
    />
  )
}
