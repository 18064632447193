import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
// import { GoogleLogin } from 'react-google-login'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { StyledTitle } from '../configurator/components/layouts'
import { Checkbox, Icon } from '@duckma/react-ds'
import { api, CreateProspect, RegisterUser } from '../../data/api'
import { emailValidator, requiredValidator } from '../../utils/validators'
import { ConfiguratorHeader } from '../../components/ConfiguratorHeader'
import { LocalizedButton } from '../../components/Button'
import { translate } from '../../i18n/dashboard_labels'
import { useHistory, useLocation } from 'react-router-dom'
import { LocalizedDescription } from '../../components/Typography'
import { useRequest } from '../../hooks/useRequest'
import { Provider } from '../../data/models'
import { PasswordField } from '../../components/PasswordField'
import { GoogleLoginButton, LocalizedLightButton } from '../../components/GoogleLoginButton'

const initialValues = {
  email: '',
  phone_number: '',
  first_name: '',
  last_name: '',
  business_name: '',
}

export const RegisterPage: React.FC = () => {
  const history = useHistory()
  const { state } = useLocation<{ createProspectData?: CreateProspect } | undefined>()
  const [provider, setProvider] = useState<Provider | null>(null)
  const [values, setValues] = useState<Omit<RegisterUser, 'provider'>>({
    ...initialValues,
    password: '',
  })
  const [valid, setValid] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)
  const [flag, setFlag] = useState(false)

  const [registerUser, { status: registerStatus }] = useRequest(api.register)
  const [saveProspect, { status: saveProspectStatus, data: saveProspectData }] = useRequest(
    api.saveProspect
  )

  const onChange = useCallback((vals, valid) => {
    setValues((v) => ({ ...v, ...vals }))
    setValid(valid)
  }, [])

  const onPasswordChange = useCallback((password, valid) => {
    setValues((v) => ({ ...v, password }))
    setPasswordValid(valid)
  }, [])

  useEffect(() => {
    if (registerStatus === 'success') {
      history.push('/verify')
    }
  }, [history, registerStatus])

  useEffect(() => {
    if (saveProspectStatus === 'success' && saveProspectData) {
      history.push(`/configurations/${saveProspectData.id}`)
    }
  }, [history, saveProspectData, saveProspectStatus])

  return (
    <PageContainer>
      <ConfiguratorHeader perc={1} onBack={provider ? () => setProvider(null) : undefined} />
      <FormContainer onSubmit={(e) => e.preventDefault()}>
        <StyledTitle text="register" />
        <LocalizedDescription text="register_text" />
        {!provider && (
          <>
            <GoogleLoginButton
              onSuccess={() => {
                if (state?.createProspectData) {
                  saveProspect(state.createProspectData)
                } else {
                  history.push('/')
                }
              }}
            />
            <LocalizedLightButton onClick={() => history.push('/login', state)}>
              <Icon
                color="black"
                name="mail"
                size="18px"
                style={{ padding: '10px', marginRight: '10px' }}
              />
              {translate('log_in_with_email')}
            </LocalizedLightButton>
            <LocalizedLightButton onClick={() => setProvider('email')}>
              <Icon
                color="black"
                name="mail"
                size="18px"
                style={{ padding: '10px', marginRight: '10px' }}
              />
              {translate('register_with_email')}
            </LocalizedLightButton>
          </>
        )}
        {provider && (
          <>
            <ControlledForm initialValues={initialValues} onChange={onChange}>
              {provider === 'email' && (
                <ControlledField
                  name="email"
                  fieldName="email"
                  size="large"
                  label="email"
                  placeholder="email"
                  required
                  validator={emailValidator()}
                />
              )}
              <ControlledField
                name="name"
                label="name"
                placeholder="name"
                fieldName="first_name"
                size="large"
                required
                validator={requiredValidator()}
              />
              <ControlledField
                name="last_name"
                label="last_name"
                placeholder="last_name"
                fieldName="last_name"
                size="large"
                required
                validator={requiredValidator()}
              />
              <ControlledField
                name="phone_number"
                label="phone_number"
                placeholder="phone_number"
                fieldName="phone_number"
                size="large"
              />
              <ControlledField
                name="business_name"
                label="business_name"
                placeholder="business_name"
                fieldName="business_name"
                size="large"
              />
            </ControlledForm>
            <PasswordField
              size="large"
              label="password"
              placeholder="password"
              value={values.password as string}
              onChange={onPasswordChange}
            />
            <span
              dangerouslySetInnerHTML={{ __html: translate('gdpr_disclaimer') }}
              style={{ fontFamily: "'Open Sans', sans-serif", color: '#555555', fontSize: '.8em' }}
            ></span>
            <Checkbox rightText={translate('gdpr_accept')} value={flag} onChange={setFlag} />
            <LocalizedButton
              text="register"
              radius={4}
              style={{ width: '50%', marginLeft: '25%', marginTop: '20px', marginBottom: '30px' }}
              disabled={!valid || !passwordValid || !flag}
              loading={registerStatus === 'loading' || saveProspectStatus === 'loading'}
              onClick={() =>
                registerUser({
                  ...values,
                  provider,
                  createProspectData: state?.createProspectData ?? undefined,
                })
              }
            />
          </>
        )}
      </FormContainer>
    </PageContainer>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: calc(100%-64px);
  max-width: 500px;
  padding: 0 32px;
  > * {
    margin: 10px 0;
  }
`

const PageContainer = styled.div`
  background-color: #fdfdfd;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`
