import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { Prospect } from '../../../data/models'
import { Icon, ColorName } from '@duckma/react-ds'
import { theme } from '../../../styles/theme'
import { LocalizedTypography } from '../../../components/Typography'
import { useTotalPrices } from '../../../hooks/useTotalPrices'
import { priceRounder } from '../../../utils/formatters'
import { useSession } from '../../../contexts/SessionContext'
import { translate } from '../../../i18n/dashboard_labels'

export const ConfigurationCard: React.FC<{ height?: string; configuration: Prospect }> = ({
  configuration: { id, title, short_description, status, created_at, items },
}) => {
  const history = useHistory()
  const { currentUser } = useSession()
  const isCustom = items.some((i) => i.customization)
  const totalPrices = useTotalPrices(items, currentUser?.multiplier_percent)
  return (
    <StyledConfigurationCard onClick={() => history.push(`/configurations/${id}`)}>
      <TitleRow>
        <Title>{title}</Title>
        <CustomizationBox>
          <CustomizationLabel
            color={isCustom ? 'tertiary50' : 'tertiary100'}
            text={isCustom ? 'personalized' : 'base'}
          />
          <Icon
            name={isCustom ? 'dashboard' : 'box'}
            color={isCustom ? 'tertiary50' : 'tertiary100'}
            size="20px"
          />
        </CustomizationBox>
      </TitleRow>
      <PricesContainer>
        <PriceItem>
          <div>{translate('fixed_total_label')}:</div>
          <div>{translate('simple_price', priceRounder(totalPrices.oneShotTotal))}</div>
        </PriceItem>
        <PriceItem>
          <div>{translate('monthly_total_label')}:</div>
          <div>{translate('simple_price', priceRounder(totalPrices.recurrentTotal))}</div>
        </PriceItem>
      </PricesContainer>
      <Footer>
        <StatusIndicator>
          {status === 'saved' ? (
            <>
              <StatusDot color="gray100" />
              <StatusLabel text="prospect_saved" />
            </>
          ) : status === 'requested' ? (
            <>
              <StatusDot color="warning100" />
              <StatusLabel text="waiting_for_offer" />
            </>
          ) : (
            <>
              <StatusDot color="success100" />
              <StatusLabel text="offer_received" />
            </>
          )}
        </StatusIndicator>
        <Timestamp>{moment(created_at).format('DD/MM/YYYY')}</Timestamp>
      </Footer>
    </StyledConfigurationCard>
  )
}

const StyledConfigurationCard = styled.div`
  cursor: pointer;
  margin-bottom: 20px;
  padding: 25px;
  min-width: 128px;
  display: flex;
  flex-direction: column;
  align-items: left;
  border-radius: 20px;
  box-shadow: 0 2px 17px 0 rgba(210, 210, 210, 0.41);
  font-family: 'Raleway', sans-serif;
`

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
`

const CustomizationBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CustomizationLabel = styled(LocalizedTypography)`
  font-size: 0.7em;
  font-weight: 100;
  margin-right: 7px;
`

const PricesContainer = styled.div`
  display: flex;
  margin-top: 15px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const StatusIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PriceItem = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #828894;
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  > :nth-child(2) {
    margin-left: 4px;
  }
`

const Title = styled.div`
  font-size: 13px;
  line-height: 18px;
  font-weight: 800;
  color: #222222;
`

const Timestamp = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-style: italic;
  color: #222222;
`

const StatusDot = styled.div<{ color: ColorName }>`
  background-color: ${(props) => theme[props.color]};
  width: 18px;
  height: 18px;
  border-radius: 18px;
`

const StatusLabel = styled(LocalizedTypography)`
  font-family: Raleway, 'sans-serif';
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  margin-left: 6px;
  color: #828894;
`
