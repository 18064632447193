import firebase, { Observer } from 'firebase/app'
import 'firebase/messaging'
import { api } from '../data/api'
import { storage } from '../data/storage'

const config = {
  apiKey: 'AIzaSyCrw5Ka_J_ap4uXQobrncNOseXukSyFVWE',
  authDomain: 'mecha-configurator.firebaseapp.com',
  databaseURL: 'https://mecha-configurator.firebaseio.com',
  projectId: 'mecha-configurator',
  storageBucket: 'mecha-configurator.appspot.com',
  messagingSenderId: '326471439434',
  appId: '1:326471439434:web:03fa53e29f110c358002b3',
  measurementId: 'G-EJCSEE8FJ8',
}

const messageObserver = {
  next: (message: any) => {
    const { title = '', options } = message
    new Notification('[Foreground]' + title, options)
  },
  error: (error: Error) => {
    console.log({ error })
  },
  complete: () => {},
} as Observer<any, Error>

const tokenRefreshObserver = (messaging: firebase.messaging.Messaging) =>
  ({
    next: async () => {
      await messaging.getToken()
    },
    error: (error: Error) => {
      console.log({ error })
    },
    complete: () => {},
  } as Observer<any, Error>)

let app: firebase.app.App
let messaging: firebase.messaging.Messaging
const initApp = () => {
  if (!app) {
    app = firebase.initializeApp(config)
  }
  if (!messaging) {
    messaging = firebase.messaging()
  }
}

export const init = async () => {
  try {
    initApp()
    await messaging.requestPermission()
    messaging.onMessage(messageObserver)
    const token = await messaging.getToken()
    if (token) {
      if (storage.getUser()) {
        api.updateFCMToken({ token })
      }
      messaging.onTokenRefresh(tokenRefreshObserver(messaging))
    }
    console.log('FCM initialized.')
  } catch (err) {
    console.error(err)
  }
}

export const deleteToken = async () => {
  initApp()
  return messaging.deleteToken(await messaging.getToken())
}
