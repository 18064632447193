import React from 'react'
import { ImmersiveModal } from '../../../components/ImmersiveModal'
import styled from 'styled-components'
import { Loader } from '../../../components/Loader'

export const InfoModal: React.FC<{
  url: string | null
  onClose: () => void
}> = ({ url, onClose }) => {
  const iframe = `<iframe src="${
    url || ''
  }" style="width: 100%; height: calc(100% - 64px); padding-top: 64px; border:none;" target="_parent" data-hj-allow-iframe=""></iframe>`

  return (
    <ImmersiveModal open={url !== null} onClose={onClose} color="white">
      <CentralLoader backgroundColor="gray20" foregroundColor="primary100" size="120px" />
      <HtmlContainer dangerouslySetInnerHTML={{ __html: iframe }} />
    </ImmersiveModal>
  )
}

const CentralLoader = styled(Loader)`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
`

const HtmlContainer = styled.div`
  position: relative;
  height: 100%;
`
