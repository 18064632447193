import {
  Typography,
  Title,
  Subtitle,
  HeaderTitle,
  SectionTitle,
  Label,
  Description,
} from '@duckma/react-ds'
import React from 'react'
import { translate } from '../i18n/dashboard_labels'

declare type LocalizedTypographyProps = Omit<React.ComponentProps<typeof Typography>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const translateTextProp = (
  textProp: string | string[],
  noTranslate?: boolean
): string | undefined => {
  if (!textProp) return
  if (noTranslate) return textProp.toString()
  if (textProp instanceof Array) return translate(textProp[0], ...textProp.slice(1))
  else return translate(textProp)
}

export const LocalizedTypography = (props: LocalizedTypographyProps) => {
  return <Typography {...props} text={translateTextProp(props.text, props.noTranslate)} />
}

declare type LocalizedTitleProps = Omit<React.ComponentProps<typeof Title>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const LocalizedTitle = (props: LocalizedTitleProps) => {
  return <Title {...props} text={translateTextProp(props.text, props.noTranslate)} />
}

declare type LocalizedSubtitleProps = Omit<React.ComponentProps<typeof Title>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const LocalizedSubtitle = (props: LocalizedSubtitleProps) => {
  return <Subtitle {...props} text={translateTextProp(props.text, props.noTranslate)} />
}

declare type LocalizedHeaderTitleProps = Omit<React.ComponentProps<typeof Title>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const LocalizedHeaderTitle = (props: LocalizedHeaderTitleProps) => {
  return <HeaderTitle {...props} text={translateTextProp(props.text, props.noTranslate)} />
}

declare type LocalizedSectionTitleProps = Omit<React.ComponentProps<typeof Title>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const LocalizedSectionTitle = (props: LocalizedSectionTitleProps) => {
  return <SectionTitle {...props} text={translateTextProp(props.text, props.noTranslate)} />
}

declare type LocalizedLabelProps = Omit<React.ComponentProps<typeof Title>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const LocalizedLabel = (props: LocalizedLabelProps) => {
  return <Label {...props} text={translateTextProp(props.text, props.noTranslate)} />
}

declare type LocalizedDescriptionProps = Omit<React.ComponentProps<typeof Title>, 'text'> & {
  text: string | string[]
  noTranslate?: boolean
}

export const LocalizedDescription = (props: LocalizedDescriptionProps) => {
  return <Description {...props} text={translateTextProp(props.text, props.noTranslate)} />
}
