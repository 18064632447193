import { Theme } from '@duckma/react-ds'

export const theme: Theme = {
  black: '#222222',
  white: '#ffffff',
  primary100: '#4d4e51',
  primary50: '#abb1be',
  primary20: '#d2f7e8',
  secondary100: '#302387',
  secondary50: '#968fc1',
  secondary20: '#d7d4ea',
  tertiary100: '#4F8A8B',
  tertiary50: '#a868e0',
  tertiary20: '#cc3333',
  gray100: '#828894',
  gray50: '#e5e7eb',
  gray20: '#f7f7f7',
  success100: '#a1dd70',
  success50: '#d0edb8',
  success20: '#e8f6dd',
  warning100: '#fdd043',
  warning50: '#fee69a',
  warning20: '#fff6da',
  danger100: '#cc3333',
  danger50: '#feaeae',
  danger20: '#fedcdc',
}
