import React, { useState } from 'react'
import styled from 'styled-components'

import LogoImg from '../../images/logo_dark.png'
import { Link } from './components/Link'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Redirect } from 'react-router-dom'
import { LocalizedSubtitle, LocalizedDescription } from '../../components/Typography'
import { LocalizedInput } from '../../components/ControlledForm'
import { LocalizedButton } from '../../components/Button'

export const PasswordReset = () => {
  const [email, setEmail] = useState('')
  const [request, { status }] = useRequest(api.recoverPassword)

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email })
      }}
    >
      {status === 'success' && <Redirect to={{ pathname: '/password-otp', state: { email } }} />}
      <Logo src={LogoImg} alt="Logo" />
      <LocalizedSubtitle text="recover_password_subtitle" style={{ marginBottom: '32px' }} />
      <LocalizedInput
        name="email"
        type="email"
        label=""
        placeholder="insert_email_placeholder"
        size="large"
        value={email}
        onChange={setEmail}
        style={{ marginBottom: '50px', width: '80%' }}
      />
      <LocalizedButton
        text="continue"
        radius={50}
        loading={status === 'loading'}
        style={{ width: '40%', marginTop: '30px' }}
      />
      <Below>
        <LocalizedDescription
          color="secondary100"
          text="you_will_receive_a_recovery_code"
          style={{ width: '80%', textAlign: 'center' }}
        />
        <Link to="/login" text="back_to_login" style={{ marginTop: '64px' }} />
      </Below>
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  @media screen and (max-width: 799px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  padding-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Logo = styled.img`
  width: 10vw;
  object-fit: contain;
`

const Below = styled.div`
  margin-top: 64px;
  padding-bottom: 128px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
`
