import React, { useState, useContext } from 'react'
import { InfoModal } from '../InfoModal'

const ModalContext = React.createContext<{
  url: string | null
  setUrl: (url: string | null) => void
}>({ url: null, setUrl: () => {} })

export const ModalContextProvider: React.FC = ({ children }) => {
  const [url, setUrl] = useState<string | null>(null)

  return (
    <ModalContext.Provider value={{ url, setUrl }}>
      <InfoModal url={url} onClose={() => setUrl(null)} />
      {children}
    </ModalContext.Provider>
  )
}

export const useModalContext = () => useContext(ModalContext)
