import React from 'react'
import styled from 'styled-components'
import { Icon, Typography, Input, Label } from '@duckma/react-ds'

import {
  LocalizedSectionTitle,
  LocalizedTypography,
  LocalizedLabel,
  LocalizedDescription,
} from '../../../../components/Typography'
import { Prospect, FinancialPlan } from '../../../../data/models'
import { theme } from '../../../../styles/theme'
import { InfoContainer, NavigationButton } from '../layouts'
import { TotalPrices } from '../../../../hooks/useTotalPrices'
import { Step } from '../../Configurator'
import { priceRounder, ellipse } from '../../../../utils/formatters'
import { FormattedPrice, PriceStyles } from '../FormattedPrice'
import { translate } from '../../../../i18n/dashboard_labels'

export const stepsOrder = ['components', 'platform', 'services', 'setup', 'summary']

export type ButtonsBoxProps = { onEdit?: () => void; onDelete?: () => void }

export const ButtonsBoxComponent: React.FC<ButtonsBoxProps> = ({ onEdit, onDelete }) => {
  return (
    <ButtonsBox>
      <ActionIcon name="pen" color="white" size="14px" onClick={onEdit} hidden={!onEdit} />
      <ActionIcon name="exit" color="white" size="14px" onClick={onDelete} hidden={!onDelete} />
    </ButtonsBox>
  )
}

export const GenericItem: React.FC<
  ButtonsBoxProps & {
    total: number
    text?: string
    buttons?: boolean
    from?: boolean
    className?: string
  }
> = ({ total, text, className, from, onDelete, onEdit, buttons }) => {
  if (!text) return <div />
  return (
    <ComponentRow className={className}>
      <LeftRowContent>
        <ComponentItem text={text} />
      </LeftRowContent>
      <PriceStyles text={[from ? 'price_from' : 'simple_price', `${total.toFixed(0)}`]} />
      {buttons ? (
        <ButtonsBoxComponent onDelete={onDelete} onEdit={onEdit} />
      ) : (
        <ButtonsBoxComponent />
      )}
    </ComponentRow>
  )
}

type Props = {
  onEditStep: (step: Step) => void
  onRequestQuotation: (financialPlan?: FinancialPlan) => void
  totalPrices: TotalPrices
  prospect: Partial<Prospect>
  update?: boolean
}

export const SummaryScreen: React.FC<Props> = ({ onEditStep, prospect, totalPrices }) => {
  if (prospect.status === 'accepted' || prospect.status === 'declined') {
    return (
      <InfoContainer>
        <LocalizedLabel
          text={[
            'status_replace',
            prospect.status === 'accepted' ? translate('accepted') : 'declined',
          ]}
        />
        <Input
          name={translate('duckmas_comment')}
          disabled
          rows={10}
          value={prospect.offer_comment}
          style={{ marginTop: '30px', marginBottom: '30px' }}
        />
        {(prospect.offer_attachments ?? []).length > 0 && (
          <>
            <LocalizedLabel text="attachments" color="black" />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit,minmax(50px,100px))',
                gridGap: '20px',
              }}
            >
              {prospect.offer_attachments?.map((a) => (
                <div style={{}}>
                  <AttachmentButton
                    radius={50}
                    iconLeft="file"
                    tooltip="show_attachment"
                    onClick={() => {
                      const link = document.createElement('a')
                      link.href = a.url
                      link.target = '_blank'
                      document.body.appendChild(link)
                      link.click()
                      document.body.removeChild(link)
                    }}
                  />
                  <Label text={ellipse(a.title)} style={{}} />
                </div>
              ))}
            </div>
          </>
        )}
      </InfoContainer>
    )
  }
  return (
    <InfoContainer>
      <PageTitle text="summary" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <ProspectTitleItem text={prospect.title} />
        {prospect.status === 'saved' && (
          <ActionIcon
            name="pen"
            color="white"
            size="14px"
            onClick={() => onEditStep('setup')}
            hidden={false}
          />
        )}
      </div>
      <br />
      <br />
      {Object.keys(totalPrices.byCategory).map((category) => (
        <React.Fragment key={category}>
          <TitleItemRow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon name="box" color="tertiary100" size="20px" />
              <TitleItem text={category} />
            </div>
            {prospect.status === 'saved' ? (
              <ButtonsBoxComponent onEdit={() => onEditStep(category as Step)} />
            ) : (
              <div />
            )}
          </TitleItemRow>
          <ItemsList>
            {(prospect?.items || [])
              .filter((i) => i.category === category)
              .map((item) => (
                <ComponentRow key={item.id}>
                  <LeftRowContent>
                    <ComponentItem>• {item.title}</ComponentItem>
                  </LeftRowContent>
                  <FormattedPrice
                    item={item}
                    multiplierPercent={prospect.multiplier_percent}
                    totalPrices={totalPrices}
                    alignRight
                  />
                </ComponentRow>
              ))}
          </ItemsList>
          <Separator />
        </React.Fragment>
      ))}
      <TitleItemRow style={{ justifyContent: 'space-between' }}>
        <LeftRowContent>
          <Icon name="euro" size="20px" color="tertiary100" />
          <TitleItem text="initial_investment" color="tertiary100" />
        </LeftRowContent>
        <LocalizedPrice
          text={[
            totalPrices.hasCustomizations ? 'price_from' : 'simple_price',
            `${priceRounder(totalPrices.oneShotTotal)}`,
          ]}
          color="tertiary100"
        />
      </TitleItemRow>
      <TitleItemRow style={{ justifyContent: 'space-between' }}>
        <LeftRowContent>
          <Icon name="euro" size="20px" color="tertiary100" />
          <TitleItem text="monthly_investment" color="tertiary100" />
        </LeftRowContent>
        <LocalizedPrice
          text={[
            totalPrices.hasCustomizations ? 'price_recurrent_from' : 'price_recurrent',
            `${priceRounder(totalPrices.recurrentTotal)}`,
          ]}
          color="tertiary100"
        />
      </TitleItemRow>
      <PriceDisclaimerContainer>
        <LocalizedDescription text="to_be_confirmed" />
        <LocalizedDescription text="vat_excluded" style={{ marginTop: '5px' }} />
      </PriceDisclaimerContainer>
    </InfoContainer>
  )
}

const ButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  align-content: center;
  @media print {
    display: none;
  }
`

const ActionIcon = styled(Icon)<{ hidden: boolean }>`
  margin: 0px 8px;
  padding: 5px;
  cursor: pointer;
  background-color: ${theme['primary100']};
  border-radius: 50%;
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`

const PageTitle = styled(LocalizedSectionTitle)`
  font-size: 1.1em;
  color: ${theme['gray100']};
  align-self: center;
  margin: 0 0 25px 0;
`

const ItemsList = styled.div`
  margin-bottom: 16px;
`

const ComponentItem = styled(LocalizedTypography)`
  font-family: 'Roboto-Light', sans-serif;
  font-size: 1em;
  font-weight: 100;
  margin: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95vw;
`

const LeftRowContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  max-width: 70vw;
`

const LocalizedPrice = styled(LocalizedTypography)`
  font-size: 1.1em;
  font-weight: 600;
  align-self: flex-end;
  white-space: nowrap;
`

const Separator = styled.hr`
  width: 100%;
  border-style: none;
  border-bottom-style: solid;
  border-bottom-color: #555;
  margin-bottom: 20px;
`

const TitleItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 17px;
`

const TitleItem = styled(LocalizedTypography)`
  font-family: 'Roboto-Light', sans-serif;
  font-size: 1.1em;
  @media screen and (max-width: 800px) {
    font-size: 0.9em;
  }
  font-weight: 600;
  margin-left: 10px;
  flex-grow: 1;
`

const ProspectTitleItem = styled(Typography)`
  font-family: 'Roboto-Light', sans-serif;
  font-size: 1.1em;
  font-weight: 600;
`

const ComponentRow = styled.div`
  margin: 16px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const AttachmentButton = styled(NavigationButton)`
  min-width: unset;
  justify-self: 'flex-start';
`

const PriceDisclaimerContainer = styled.div`
  display: flex;
  align-items: right;
  flex-direction: column;
  width: 100%;
  text-align: right;
  > * {
    font-size: 0.7em;
    color: #828894;
  }
`
