export type Role = 'user' | 'seller' | 'admin'
export type Provider = 'email' | 'google'

export interface User {
  id: string
  email: string
  role: Role
  first_name: string
  last_name: string
  created_at: string
  seller: User | null
  status: 'active' | 'blocked'
  phone_number?: string
  business_name?: string
  type?: 'private' | 'company'
  tax_code_or_vat_code?: string
  multiplier_percent: number
}
export type MinimalUser = Pick<User, 'id' | 'email' | 'first_name' | 'last_name'>
export interface Media {
  id: string
  title: string
  type: 'image' | 'doc'
  url: string
  thumbnail_url?: string
  size_bytes: number
  created_at: string
}

export type Category = 'components' | 'graphics' | 'platforms' | 'services'

export enum PriceRecurrence {
  ONESHOT = 'oneshot',
  MONTHLY = 'monthly',
}

export enum PriceModel {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export interface Item {
  id: string
  title: string
  order: number
  image: Media
  price: number
  price_recurrence: PriceRecurrence
  price_model: PriceModel
  short_description: string
  details_url: string
  mandatory: boolean
  requires: Pick<Item, 'id' | 'title'>[]
  category: Category
  created_at: string
}

export type ProspectStatus = 'saved' | 'requested' | 'accepted' | 'declined'

export type ProspectItem = Pick<
  Item,
  'id' | 'title' | 'price' | 'price_recurrence' | 'price_model' | 'category'
> & {
  customization: boolean
  customization_notes?: string
}

export type FinancialPlan = {
  percentage_upfront: number
  duration_months: number
}

export interface Prospect {
  id: string
  title: string
  status: ProspectStatus
  short_description: string
  created_at: string
  items: ProspectItem[]
  user: User
  attachments?: Media[]
  offer_comment?: string
  offer_attachments?: Media[]
  financial_plan: FinancialPlan
  company_name?: string
  customer_name?: string
  multiplier_percent: number
}

export type ProspectCreate = Omit<
  Prospect,
  'id' | 'created_at' | 'status' | 'user' | 'financial_plan' | 'multiplier_percent'
>
