import React, { useEffect, useState, useCallback, useReducer } from 'react'
import { useParams, useHistory, Prompt } from 'react-router-dom'
import _ from 'lodash'

import { SummaryScreen } from './components/pages/SummaryPage'
import { useRequest } from '../../hooks/useRequest'
import { api, CreateProspect } from '../../data/api'
import { ProspectCreate, Category } from '../../data/models'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { ItemsPage } from './components/pages/ItemsPage'
import { InfoPage } from './components/pages/InfoPage'
import { ConfigurationStep } from './components/ConfigurationStep'
import { useTotalPrices } from '../../hooks/useTotalPrices'
import { FinancialPlanModal } from './components/FinancialPlanModal'
import { analytics } from '../../data/analytics'
import { useSession } from '../../contexts/SessionContext'

export type Step = Category | 'setup' | 'summary'
export const stepsOrder: Step[] = [
  'components',
  'graphics',
  'platforms',
  'services',
  'setup',
  'summary',
]

type State = { step: Step; editing: boolean }
const stepReducer = (
  { step, editing }: State,
  action: 'prev' | 'next' | 'goto_summary' | { type: 'edit'; step: Step }
): State => {
  switch (action) {
    case 'prev':
      return { editing, step: stepsOrder[Math.max(stepsOrder.indexOf(step) - 1, 0)] }
    case 'next':
      return {
        // Even if returning to summary, leave editing = true.
        // An useEffect hook will asynchronously save our prospect and set this variable to false once done.
        editing,
        step: editing
          ? stepsOrder[stepsOrder.length - 1]
          : stepsOrder[Math.min(stepsOrder.indexOf(step) + 1, stepsOrder.length - 1)],
      }
    case 'goto_summary':
      return { editing: false, step: stepsOrder[stepsOrder.length - 1] }
    default:
      switch (action.type) {
        case 'edit':
          return { editing: true, step: action.step }
      }
  }
}

export const Configurator: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { currentUser } = useSession()

  const [getProspect, { data: prospectData }] = useRequest(api.getProspect)
  const [getItems, { data: itemsData }] = useRequest(api.getItems)
  const [getMe] = useRequest(api.getMe)
  const [saveProspect, { status: createStatus, data: createData }] = useRequest(api.saveProspect)
  const [patchProspect, { status: patchStatus, data: patchData }, resetPatch] = useRequest(
    api.patchProspect
  )

  const [state, dispatch] = useReducer(stepReducer, { step: stepsOrder[0], editing: false })
  const [prospectToCreate, setProspectToCreate] = useState<ProspectCreate>({
    title: '',
    short_description: '',
    customer_name: '',
    company_name: '',
    items: [],
  })
  const [financialPlanModalOpen, setFinancialPlanModalOpen] = useState(false)

  useEffect(() => {
    if (id && !prospectData) {
      getProspect({ id })
    } else if (prospectData && itemsData) {
      setProspectToCreate(prospectData)
      dispatch('goto_summary')
    }
  }, [prospectData, itemsData, getProspect, id])

  useEffect(() => {
    getItems({
      order_by: 'order',
      order_direction: 'asc',
      page: 0,
      page_size: 100,
    })
    if (currentUser) {
      getMe({})
    }
  }, [getItems, getMe, currentUser])

  useEffect(() => {
    if (itemsData) {
      setProspectToCreate((p) => ({
        ...p,
        items: [
          ...p.items,
          ...itemsData.items
            .filter((i) => i.mandatory)
            .filter((i) => p.items.find((pi) => pi.id === i.id) === undefined)
            .map(({ id, title, category, price, price_model, price_recurrence }) => ({
              id,
              title,
              price,
              price_model,
              price_recurrence,
              category,
              customization: false,
            })),
        ],
      }))
    }
  }, [itemsData])

  useEffect(() => {
    if (patchData) {
      dispatch('goto_summary')
      setProspectToCreate(patchData)
      resetPatch()
    }
  }, [patchData, resetPatch])

  useEffect(() => {
    if (state.step !== 'summary') {
      return
    }
    if (id && state.editing && patchStatus === 'idle') {
      patchProspect({
        id,
        update: {
          ..._.omit(prospectToCreate, 'user'),
          status: 'saved',
          items: _.uniqBy(prospectToCreate.items, 'id'),
          attachments: (prospectToCreate.attachments ?? []).map((a) => a.id),
        },
      })
    }
    if (!id && createStatus === 'idle') {
      saveProspect({
        ..._.omit(prospectToCreate, 'user'),
        status: 'saved',
        items: _.uniqBy(prospectToCreate.items, 'id'),
        attachments: (prospectToCreate.attachments ?? []).map((a) => a.id),
      })
    }
  }, [state, patchProspect, patchStatus, id, prospectToCreate, createStatus, saveProspect])

  useEffect(() => {
    analytics.pageChangeEvent(`/configurator/${state.step}`)
  }, [state.step])

  useSuccessToast(
    createStatus,
    'configuration_created',
    createData ? `/configurations/${createData.id}` : '/'
  )
  useSuccessToast(
    patchStatus,
    'configuration_updated',
    patchData ? `/configurations/${patchData.id}` : '/'
  )

  const totalPrices = useTotalPrices(
    prospectToCreate?.items || [],
    currentUser?.multiplier_percent ?? 100,
    !id && ['components', 'graphics', 'platforms', 'services'].includes(state.step)
      ? (state.step as Category)
      : undefined
  )
  const infoPageOnChange = useCallback(
    (setupValues) =>
      setProspectToCreate((p) => ({
        ...p,
        title: setupValues.title,
        short_description: setupValues.short_description || '',
        customer_name: setupValues.customer_name,
        company_name: setupValues.company_name,
        attachments: setupValues.attachments,
      })),
    []
  )

  switch (state.step) {
    case 'components':
    case 'graphics':
    case 'platforms':
    case 'services':
      return (
        <ConfigurationStep
          totalPrices={totalPrices}
          prospect={prospectToCreate}
          step={state.step}
          onNext={() => dispatch('next')}
          nextError={
            prospectToCreate.items.every((i) => i.category !== state.step)
              ? 'select_item_first'
              : undefined
          }
          onPre={() => {
            if (state.step !== 'components') {
              dispatch('prev')
            } else {
              history.push('/')
            }
          }}
        >
          <Prompt
            when={prospectToCreate?.items?.length > 0}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          <ItemsPage
            showPrices={currentUser !== undefined}
            multiplierPercent={currentUser?.multiplier_percent}
            totalPrices={totalPrices}
            items={
              itemsData?.items
                .filter((i) => i.category === state.step)
                .map((i) => ({
                  ...i,
                  price: _.get(prospectToCreate, ['final_price_list', i.id], i.price),
                })) || []
            }
            category={state.step}
            selected={prospectToCreate.items
              .filter((i) => i.category === state.step)
              .map(({ id, customization_notes }) => ({
                id,
                description: customization_notes,
              }))}
            setItems={(items) => {
              window.onbeforeunload = () => ''
              setProspectToCreate({
                ...prospectToCreate,
                items: [
                  ...prospectToCreate.items.filter((i) => i.category !== state.step),
                  ...(itemsData?.items
                    .map((realComponent) => ({
                      ...realComponent,
                      matched: items.find((i) => i.id === realComponent.id),
                    }))
                    .filter((rc) => rc.matched)
                    .map(
                      ({ id, title, category, price, price_model, price_recurrence, matched }) => ({
                        id,
                        title,
                        price: _.get(prospectToCreate, ['final_price_list', id], price),
                        price_model,
                        price_recurrence,
                        category,
                        customization: matched?.description !== undefined,
                        customization_notes: matched?.description,
                      })
                    ) || []),
                ],
              })
            }}
          />
        </ConfigurationStep>
      )
    case 'setup':
      return (
        <ConfigurationStep
          totalPrices={totalPrices}
          prospect={prospectToCreate}
          step={state.step}
          onNext={() => {
            if (currentUser) {
              dispatch('next')
            } else {
              // Go to the register page. That will care of our prospect once the user registers
              history.push('/register', {
                createProspectData: {
                  ..._.omit(prospectToCreate, 'user'),
                  status: 'saved',
                  items: _.uniqBy(prospectToCreate.items, 'id'),
                  attachments: (prospectToCreate.attachments ?? []).map((a) => a.id),
                } as CreateProspect,
              })
            }
          }}
          nextText="save"
          nextError={
            !prospectToCreate.title || prospectToCreate.title.length === 0
              ? 'insert_title_first'
              : undefined
          }
          onPre={() => dispatch('prev')}
        >
          <InfoPage
            initialValues={{
              title: prospectToCreate.title,
              short_description: prospectToCreate.short_description,
              customer_name: prospectToCreate.customer_name,
              company_name: prospectToCreate.company_name,
              attachments: prospectToCreate.attachments || [],
            }}
            onChange={infoPageOnChange}
          />
        </ConfigurationStep>
      )
    case 'summary':
      return (
        <>
          <FinancialPlanModal
            open={financialPlanModalOpen}
            totalPrices={totalPrices}
            onClose={() => setFinancialPlanModalOpen(false)}
            onConfirm={(plan) => {
              patchProspect({
                id,
                update: {
                  status: 'requested',
                  financial_plan: plan,
                },
              })
              setFinancialPlanModalOpen(false)
            }}
          />
          <ConfigurationStep
            totalPrices={totalPrices}
            prospect={prospectToCreate}
            step={state.step}
            nextText="request_quotation"
            onNext={() => {
              patchProspect({
                id,
                update: {
                  status: 'requested',
                },
              })
            }}
            onFinancialPlan={() => setFinancialPlanModalOpen(true)}
            onPre={() => history.push('/')}
          >
            <SummaryScreen
              totalPrices={totalPrices}
              prospect={prospectToCreate}
              update={id ? true : false}
              onEditStep={(step: Step) => dispatch({ type: 'edit', step })}
              onRequestQuotation={() => {
                patchProspect({
                  id,
                  update: {
                    status: 'requested',
                  },
                })
              }}
            />
          </ConfigurationStep>
        </>
      )
    default:
      return <div />
  }
}
