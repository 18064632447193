import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Paginator } from '@duckma/react-ds'
import { LocalizedLabel } from '../../components/Typography'
import { ConfigurationCard } from './components/ConfigurationCard'

const CenteredSubtitle: React.FC<{ text: string }> = ({ text }) => (
  <Centered>
    <LocalizedLabel text={text} />
  </Centered>
)

export const Configurations: React.FC = () => {
  const [getProspects, { status, data }] = useRequest(api.getProspects)

  const [page, setPage] = useState<number>(0)

  const getProspectsWithParams = useCallback(
    () =>
      getProspects({
        order_by: 'created_at',
        order_direction: 'desc',
        page,
        page_size: 10,
      }),
    [getProspects, page]
  )

  useEffect(() => {
    getProspectsWithParams()
  }, [getProspectsWithParams])

  return (
    <>
      <Grid>
        {data ? (
          data.items.map((c) => <ConfigurationCard key={c.id} configuration={c} height="15vw" />)
        ) : (
          <CenteredSubtitle text="loading" />
        )}
      </Grid>
      {status === 'success' && data && data.items.length === 0 && (
        <CenteredSubtitle text="no_configurations" />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Footer>
          <Paginator
            currentPage={page}
            totalPages={data.pagination.total_pages}
            onPageSelect={setPage}
          />
        </Footer>
      )}
    </>
  )
}

const Grid = styled.div`
  width: calc(100% - 10vw);
  display: grid;
  flex-direction: column;
  margin-top: 5vh;
  padding: 0 5vw;
  grid-template: 1fr / 1fr 1fr;
  grid-column-gap: 5vw;
  @media screen and (max-width: 800px) {
    grid-template: 1fr / 1fr;
  }
`

const Centered = styled.div`
  grid-area: 1 / 1 / 4 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 54px;
`
