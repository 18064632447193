import { useState, useCallback, useEffect } from 'react'
import { useRequest } from './useRequest'

export const useQueue = <T>(initial: T[]) => {
  const [queue, setQueue] = useState<T[]>(initial)

  const enqueue = useCallback((element: T) => {
    setQueue((queue) => [...queue, element])
  }, [])

  const dequeue = useCallback(() => {
    if (queue.length === 0) {
      return null
    }
    const head = queue[0]
    setQueue((queue) => queue.slice(1))
    return head
  }, [queue])

  return [queue, { enqueue, dequeue }] as [
    T[],
    { enqueue: (newElement: T) => void; dequeue: () => T | null }
  ]
}

export const useNetworkQueue = <P, T>(
  apiCall: (data: P) => Promise<T>,
  onComplete: (result: T) => void = () => {}
) => {
  const [request, { status, data }, reset] = useRequest(apiCall)
  const [queue, { enqueue, dequeue }] = useQueue<P>([])

  const [lock, setLock] = useState(false)

  useEffect(() => {
    if (status === 'success' && data && lock) {
      onComplete(data)
      setLock(false)
      dequeue()
      reset()
    }
    if (queue.length > 0 && status === 'idle' && !lock) {
      setLock(true)
      request(queue[0])
    }
  }, [queue, status, lock, setLock, request, dequeue, data, onComplete, reset])

  return [queue, { enqueue }] as [P[], { enqueue: (newElement: P) => void }]
}
