import styled from 'styled-components'
import { Icon } from '@duckma/react-ds'
import { LocalizedButton } from '../../../components/Button'
import { LocalizedSubtitle, LocalizedTitle } from '../../../components/Typography'
import { theme } from '../../../styles/theme'

export const BackIcon = styled(Icon)`
  cursor: pointer;
`

export const BackSection = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TopNavigation = styled.div`
  height: 3vh;
  min-height: 32px;
  padding-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Footer = styled.div`
  height: 92px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`

export const NavigationButton = styled(LocalizedButton)<{ selected?: boolean }>`
  padding: 24px 24px;
  width: fit-content;
  min-width: 128px;
  margin: 8px;
  ${(props) => {
    if (props.selected) {
      return `
        background-color: ${theme['tertiary20']};
      `
    }
  }}
`

export const StepLabel = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  font-weight: bold;
  color: rgba(42, 43, 52, 0.43);
`

export const InfoButton = styled(LocalizedButton)`
  margin-top: 40px;
  width: fit-content;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: #abb1be;
  border-color: #abb1be;
  border-width: 2px;
`

export const StyledTitle = styled(LocalizedTitle)`
  font-size: 1.5em;
  color: #000;
  font-weight: 900;
`

export const StyledSubtitle = styled(LocalizedSubtitle)`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 0.8em;
`

export const RightPanel = styled.div`
  margin: 32px;
  max-width: 384px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

export const LeftPanel = styled.div`
  margin: 32px;
  max-width: 384px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
`

export const InfoContainer = styled.div`
  margin: 0px 32px;
  @media screen and (max-width: 800px) {
    margin: 0px 10px;
  }
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  padding-bottom: 96px;
  @media screen and (min-width: 1000px) {
    padding-left: calc(50vw - 500px);
    padding-right: calc(50vw - 500px);
  }
`

export const TitleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const ComponentImage = styled.img`
  object-fit: contain;
  vertical-align: top;
  width: 384px;
  height: 512px;
`

export const StyledLogo = styled.img`
  left: 0;
  cursor: pointer;
  max-width: 80px;
  margin: 60px;
  @media print {
    margin: 30px;
  }
`
