import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import * as serviceWorker from './serviceWorker'
import App from './App'
import { init as initFirebase } from './libs/firebase'

try {
  gapi.load('auth2', function () {
    gapi.auth2.init({
      client_id: '326471439434-6tlvs7tg5ase651gkt3fbko4m3f9qjd1.apps.googleusercontent.com',
    })
  })
} catch (err) {
  console.error(err)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} pauseOnFocusLoss={false} />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()
initFirebase()
