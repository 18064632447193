import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import LogoImg from '../../images/logo_dark.png'
import { Link } from './components/Link'
import { useRequest } from '../../hooks/useRequest'
import { api, CreateProspect } from '../../data/api'
import { useHistory, useLocation } from 'react-router-dom'
import { LocalizedInput } from '../../components/ControlledForm'
import { LocalizedLabel, LocalizedSubtitle } from '../../components/Typography'
import { LocalizedButton } from '../../components/Button'
import { GoogleLoginButton } from '../../components/GoogleLoginButton'

export const LoginPage = () => {
  const { state } = useLocation<{ createProspectData?: CreateProspect } | undefined>()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [request, { status }, reset] = useRequest(api.login)
  const [saveProspect, { status: saveProspectStatus, data: saveProspectData }] = useRequest(
    api.saveProspect
  )

  useEffect(() => {
    if (status === 'success') {
      reset()
      if (state?.createProspectData) {
        saveProspect(state.createProspectData)
      } else {
        history.push('/')
      }
    }
  }, [history, reset, saveProspect, state, status])

  useEffect(() => {
    if (saveProspectStatus === 'success' && saveProspectData) {
      history.push(`/configurations/${saveProspectData.id}`)
    }
  }, [history, saveProspectData, saveProspectStatus])

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email, password })
      }}
    >
      <Logo src={LogoImg} alt="Logo" />
      <LocalizedSubtitle text="access" style={{ marginBottom: '32px' }} />
      <LocalizedInput
        name="email"
        type="email"
        label=""
        placeholder="insert_email_placeholder"
        size="large"
        autocomplete="email"
        value={email}
        onChange={setEmail}
        style={{ marginBottom: '30px', width: '80%' }}
      />
      <LocalizedInput
        name="password"
        type="password"
        label=""
        placeholder="insert_password"
        size="large"
        autocomplete="current-password"
        value={password}
        onChange={setPassword}
        style={{ marginBottom: '15px', width: '80%' }}
      />
      <LocalizedLabel text="or" />
      <GoogleLoginButton
        onSuccess={() => history.push('/')}
        style={{ width: '80%', marginTop: '10px', marginBottom: '30px' }}
      />
      <StyledLink to="/password-reset" text="forgot_password_question" />
      <LocalizedButton
        text="enter_button"
        radius={50}
        loading={status === 'loading'}
        style={{ width: '40%', marginTop: '20px' }}
      />
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  @media screen and (max-width: 799px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  padding-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Logo = styled.img`
  max-width: 80px;
  object-fit: contain;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  width: 80%;
  :hover {
    text-decoration: none;
  }
`
