import React, { useState, useEffect } from 'react'
import { Modal, Radios } from '@duckma/react-ds'
import styled from 'styled-components'

import { LocalizedButton } from '../../../components/Button'
import { translate } from '../../../i18n/dashboard_labels'
import { LocalizedTypography, LocalizedLabel } from '../../../components/Typography'
import { FinancialPlan } from '../../../data/models'
import { useRequest } from '../../../hooks/useRequest'
import { api } from '../../../data/api'
import { priceRounder } from '../../../utils/formatters'
import { TotalPrices } from '../../../hooks/useTotalPrices'

export const FinancialPlanModal: React.FC<{
  open: boolean
  totalPrices: TotalPrices
  onConfirm: (plan: FinancialPlan) => void
  onClose: () => void
}> = ({ open, totalPrices, onConfirm, onClose }) => {
  const [requestConfig, { data: config }] = useRequest(api.getFinancialPlanConfig)
  const [percentageUpfront, setPercentageUpfront] = useState<number | null>(null)
  const [durationMonths, setDurationMonths] = useState<number | null>(null)

  useEffect(() => {
    requestConfig({})
  }, [requestConfig])

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset'
  }, [open])

  useEffect(() => {
    if (config && !percentageUpfront) {
      setPercentageUpfront(config.payment_upfront[0])
    }
    if (config && !durationMonths) {
      setDurationMonths(config.duration_months[0].duration)
    }
  }, [config, durationMonths, percentageUpfront])

  const stillToPay = (totalPrices.oneShotTotal * (100 - (percentageUpfront || 100))) / 100
  const feePercentage =
    (config?.duration_months.find((dm) => dm.duration === durationMonths)?.percentage ?? 100) / 100
  const addedMonthlyFee = (stillToPay * (1 + feePercentage)) / (durationMonths || 1)

  return (
    <StyledModal open={open} onClose={onClose} title={translate('financial_plan')} color="white">
      <Content>
        <div style={{ gridArea: 'a', marginBottom: '20px' }}>
          <LocalizedTypography type="sectionTitle" text="percentage_upfront" />
          {config && (
            <VerticalRadios
              value={
                percentageUpfront ? String(percentageUpfront) : String(config.payment_upfront[0])
              }
              choices={config.payment_upfront.map((key) => ({
                key: String(key),
                label: String(key) + '%',
              }))}
              onChange={(choice) => setPercentageUpfront(parseInt(choice))}
            />
          )}
        </div>
        <div style={{ gridArea: 'b', marginBottom: '20px' }}>
          <LocalizedTypography type="sectionTitle" text="duration_monthly" />
          {config && (
            <VerticalRadios
              value={
                durationMonths ? String(durationMonths) : String(config.duration_months[0].duration)
              }
              choices={config.duration_months.map(({ duration }) => ({
                key: String(duration),
                label: String(duration) + ' ' + translate('months'),
              }))}
              onChange={(choice) => setDurationMonths(parseInt(choice))}
            />
          )}
        </div>
        {config && (
          <div style={{ gridArea: 'c' }}>
            <LocalizedTypography type="sectionTitle" text="total_prices" />
            <br />
            <br />
            <LocalizedPrice
              text={[
                totalPrices.hasCustomizations ? 'price_from' : 'simple_price',
                `${priceRounder((totalPrices.oneShotTotal * (percentageUpfront || 1)) / 100)}`,
              ]}
            />
            <LocalizedLabel text="50_now_50_deploy" style={{ marginLeft: '20px' }} />
            <br />
            <br />
            <LocalizedPrice
              text={[
                totalPrices.hasCustomizations ? 'price_recurrent_from' : 'price_recurrent',
                `${priceRounder(totalPrices.recurrentTotal + addedMonthlyFee)}`,
              ]}
            />
            <LocalizedLabel
              text={['first_months_replace', String(durationMonths)]}
              style={{ marginLeft: '20px' }}
            />
            <br />
            <br />
            <LocalizedPrice
              text={[
                totalPrices.hasCustomizations ? 'price_recurrent_from' : 'price_recurrent',
                `${priceRounder(totalPrices.recurrentTotal)}`,
              ]}
            />
            <LocalizedLabel text="after" style={{ marginLeft: '20px' }} />
            <br />
            <br />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '30px',
              }}
            >
              <ContinueButton
                text="request_quotation"
                radius={50}
                color="danger"
                onClick={() => {
                  if (percentageUpfront && durationMonths) {
                    onConfirm({
                      percentage_upfront: percentageUpfront,
                      duration_months: durationMonths,
                    })
                  }
                }}
              />
            </div>
          </div>
        )}
      </Content>
    </StyledModal>
  )
}

const ContinueButton = styled(LocalizedButton)`
  width: fit-content;
  padding: 16px 32px;
  align-self: flex-end;
  margin-top: 16px;
`

const LocalizedPrice = styled(LocalizedTypography)`
  font-size: 1.1em;
  font-weight: 600;
  align-self: flex-end;
  white-space: nowrap;
`

const Content = styled.div`
  padding: 1% 2vw;
  min-height: calc(96% - 64px);
  flex-direction: column;
  overflow-y: scroll;

  display: grid;
  grid-template-areas:
    'a b'
    'c c';
  grid-template-rows: auto 1fr;
  @media screen and (max-width: 800px) {
    grid-template-areas:
      'a'
      'b'
      'c';
    grid-template-rows: auto auto 1fr;
  }
`

const StyledModal = styled(Modal)`
  position: absolute;
  left: 15vw;
  right: 15vw;
  top: 8vh;
  bottom: 8vh;
  max-height: 84vh;
  overflow-y: scroll;
  outline: none;
  border-radius: 20px;
  @media screen and (max-width: 800px) {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    max-height: 100vh;
    min-height: 100vh;
  }
  * {
    z-index: 2;
  }
`

const VerticalRadios = styled(Radios)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  > * {
    margin-left: 0 !important;
    margin-top: 20px !important;
  }
`
