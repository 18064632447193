import React from 'react'

import { Item, Category } from '../../../../data/models'
import { ListStepPage } from '../ListStepPage'
import { TotalPrices } from '../../../../hooks/useTotalPrices'

export type Props = {
  items: Item[]
  category: Category
  selected: { id: string; description?: string }[]
  multiplierPercent?: number
  totalPrices: TotalPrices
  showPrices: boolean
  setItems: (selectedItems: { id: string; description?: string }[]) => void
}

export const ItemsPage: React.FC<Props> = ({
  items = [],
  category,
  multiplierPercent,
  totalPrices,
  selected = [],
  showPrices,
  setItems,
}) => {
  return (
    <>
      <ListStepPage
        allowPersonalized={category === 'components'}
        multipleSelection={['components', 'services'].includes(category)}
        title={category}
        subtitle="components_section_subtitle"
        currentSelection={selected}
        multiplierPercent={multiplierPercent}
        totalPrices={totalPrices}
        items={items.map((item) => ({
          ...item,
          icon: 'smartphone',
        }))}
        onChange={(components) => setItems(components as { id: string; description?: string }[])}
        noTranslate
        showPrices={showPrices}
      />
    </>
  )
}
