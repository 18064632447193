import React from 'react'

export const Logo = () => (
  <svg width="42px" height="53px" viewBox="0 0 42 53" version="1.1">
    <title>logo</title>
    <defs>
      <polygon id="path-1" points="0 0 26.3340012 0 26.3340012 52.6680024 0 52.6680024"></polygon>
    </defs>
    <g id="redesgin-home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="1" transform="translate(-60.000000, -41.000000)">
        <g id="logo" transform="translate(60.000000, 41.000000)">
          <path
            d="M32.9463657,19.5791067 C32.8205581,19.6951244 32.9046674,19.9080009 33.0764499,19.9080009 L33.7931608,19.9080009 C33.7931608,19.9080009 32.8579796,19.9080009 35.3987213,19.9080009 C39.4423817,19.9080009 42.0903983,16.4523052 38.5118337,16.952565 C36.4689762,17.2395935 35.4732079,16.8273227 34.9892232,16.4246312 C34.8776715,16.3316751 34.7098093,16.3919901 34.6820105,16.5310695 C34.4446512,17.7029548 33.8352154,18.7545649 32.9463657,19.5791067"
            id="Fill-4"
            fill="#4D4E51"
          ></path>
          <path
            d="M27.7202091,13.7340006 C28.2770004,13.7340006 28.7280013,14.1849285 28.7280013,14.7420007 C28.7280013,15.299444 28.2725461,15.7500007 27.7202091,15.7500007 C27.1626754,15.7500007 26.7120457,15.299444 26.7120457,14.7420007 C26.706849,14.1849285 27.1578499,13.7340006 27.7202091,13.7340006 Z M27.6594826,20.916001 C31.1025053,20.916001 33.8940016,18.1519528 33.8940016,14.7420007 C33.8940016,11.3320485 31.1025053,8.56800039 27.6594826,8.56800039 C24.2111428,8.56800039 21.420001,11.3320485 21.420001,14.7420007 C21.420001,18.1519528 24.2111428,20.916001 27.6594826,20.916001 L27.6594826,20.916001 Z"
            id="Fill-5"
            fill="#4D4E51"
          ></path>
          <path
            d="M40.2462144,31.9873174 L30.7122572,25.837788 C28.6014037,24.4758305 25.1501352,24.4849617 23.0438696,25.8560504 L13.675435,31.9641381 C11.5695224,33.3352268 11.5695224,35.5822108 13.675435,36.9578651 L23.0392816,43.0659528 C25.1451942,44.4370414 28.5968156,44.4461727 30.7073162,43.0842152 L40.2419793,36.935037 C42.3613031,35.5776451 42.3613031,33.3489236 40.2462144,31.9873174"
            id="Fill-6"
            fill="#4D4E51"
          ></path>
          <g id="ios">
            <mask id="mask-2" fill="white"></mask>
            <g id="Mask"></g>
            <image mask="url(#mask-2)" x="0" y="0" width="52.6680024" height="52.6680024" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
