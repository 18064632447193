import React from 'react'
import styled from 'styled-components'
import { Typography } from '@duckma/react-ds'

import { ProspectItem, PriceModel, PriceRecurrence, Category } from '../../../data/models'
import { TotalPrices, priceWithPriceList, categories } from '../../../hooks/useTotalPrices'
import { translate } from '../../../i18n/dashboard_labels'
import { priceRounder } from '../../../utils/formatters'
import { LocalizedTypography } from '../../../components/Typography'

const getPreviousCategory = (category: Category) =>
  categories[Math.max(categories.findIndex((c) => c === category) - 1, 0)]

export const FormattedPrice: React.FC<{
  item: ProspectItem
  multiplierPercent?: number
  totalPrices: TotalPrices
  alignRight?: boolean
}> = ({ item, multiplierPercent, totalPrices, alignRight = false }) => {
  const lastCategoryTotal = totalPrices.byCategory[getPreviousCategory(item.category)] ?? {
    oneShotTotal: 0,
    recurrentTotal: 0,
  }
  const lastTotal = lastCategoryTotal.oneShotTotal

  const value =
    item.price_model === PriceModel.FIXED
      ? priceWithPriceList(item, multiplierPercent)
      : (priceWithPriceList(item, multiplierPercent) / 100 - 1) * lastTotal

  return (
    <PriceStyles alignRight={alignRight}>
      {/* {item.customization_notes && <span>{translate('from')}</span>}
      {priceRounder(value)}€ */}
      <LocalizedTypography
        text={[
          item.customization ||
          (item.price_model !== PriceModel.FIXED && totalPrices.hasCustomizations)
            ? 'price_from'
            : 'simple_price',
          `${priceRounder(value)}`,
        ]}
        style={{ fontSize: '1.1em', fontWeight: '600' }}
      />
      {item.price_recurrence === PriceRecurrence.MONTHLY && '/' + translate('month')}
    </PriceStyles>
  )
}

export const PriceStyles = styled(Typography)<{ alignRight: boolean }>`
  font-size: 1.1em;
  @media screen and (max-width: 800px) {
    font-size: 0.9em;
  }
  font-weight: 600;
  text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
  white-space: nowrap;
`
